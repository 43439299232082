import { getCurrentUser } from '../../services/backend/backendService';
import { EnvironmentVariables, JDUser, OauthToken } from '../../types';

export const getAndSetCurrentUser = async (
  environmentVariables: EnvironmentVariables | null,
  oauthToken: OauthToken | null,
  setCurrentUser: (user: JDUser) => void,
): Promise<void> => {
  if (!(environmentVariables && oauthToken)) {
    return;
  }

  const user = await getCurrentUser(environmentVariables.apiBaseUrl, oauthToken);

  setCurrentUser(user);
};
