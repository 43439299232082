import { ListItem, makeStyles, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import ApplicationContext from '../../../../contexts/ApplicationContext';
import ReactGA from 'react-ga';
import { categories } from '../../../../google-analytics/constants';
import PropTypes from 'prop-types';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import { clearSelectedAsset } from '../../../../contexts/ContextUtils';

interface Props {
  style: any;
}

const useStyles = makeStyles(theme => ({
  listItem: {
    '&:hover': {
      backgroundColor: '#f0f5f8 !important',
    },
  },
  listItemSelected: {
    backgroundColor: '#f0f5f8 !important',
    color: theme.palette.primary.main,
  },
  topListItemText: {
    paddingLeft: '8px',
    fontWeight: 'bold',
  },
}));

const ViewAllAssetsHeader: React.FC<Props> = props => {
  const classes = useStyles();
  const { style } = props;
  const {
    selectAsset,
    setShowSplashScreen,
    selectedAsset,
    showSplashScreen,
    setSelectedClusteredGroupDate,
    setSelectedClusteredGroupName,
    setClusteredGroups,
    setBucketMetadata,
    setCentroids,
    setCenterOfCentroids,
  } = useContext(ApplicationContext);
  const viewAllAssetsSelected = () => !selectedAsset && !showSplashScreen;

  return (
    <ListItem
      button
      key={'allassets'}
      selected={viewAllAssetsSelected()}
      classes={{
        root: classes.listItem,
        selected: classes.listItemSelected,
      }}
      onClick={() => {
        if (viewAllAssetsSelected()) {
          return;
        }
        ReactGA.event({
          category: categories.NavigateStay.analyticKey,
          action: categories.NavigateStay.actions.allAssetsListItem.analyticKey,
        });
        clearSelectedAsset(
          selectAsset,
          setSelectedClusteredGroupDate,
          setSelectedClusteredGroupName,
          setClusteredGroups,
          setBucketMetadata,
          setCentroids,
          setCenterOfCentroids,
          setShowSplashScreen,
        );
      }}
      style={style}
    >
      <PhoneAndroidIcon fontSize="small" />
      <Typography className={classes.topListItemText}>View All Assets</Typography>
    </ListItem>
  );
};

ViewAllAssetsHeader.propTypes = {
  style: PropTypes.any.isRequired,
};

export default ViewAllAssetsHeader;
