import { useContext, useEffect } from 'react';
import ApplicationContext from '../../contexts/ApplicationContext';
import { getAndSetAccessToken } from './GetAndSetAccessToken'

export const useAccessToken = () => {
  const { setOauthToken } = useContext(ApplicationContext);

  useEffect(() => {
    getAndSetAccessToken(setOauthToken);
  }, [setOauthToken]);
};
