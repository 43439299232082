import {
  JDAsset,
  JDAssetLocation,
  JDOrganization,
  JDUser,
  OauthToken,
  GetAssetLocationsParams,
  GetAssetLocationsResponse,
  GetOrganizationsResponse,
  GetOrganizationAssetsWithLocationResponse,
  JDAssetRaw,
} from '../../types';
import { backendDelete, backendGet } from './backendHelper';
import { toAsset } from './jd-response-mappers/Asset';
import { toAssetLocation } from './jd-response-mappers/AssetLocation';

export const getCurrentUser = async (apiBaseUrl: string, oAuthToken: OauthToken): Promise<JDUser> => {
  return await backendGet('/currentUser', oAuthToken, apiBaseUrl);
};

export const getAssetLocations = async (
  apiBaseUrl: string,
  oAuthToken: OauthToken,
  assetId: string,
  queryParameters: Partial<GetAssetLocationsParams>,
): Promise<Array<JDAssetLocation>> => {
  const response: GetAssetLocationsResponse = await backendGet(
    `/assets/${assetId}/locations`,
    oAuthToken,
    apiBaseUrl,
    queryParameters,
  );

  return response.values.map(toAssetLocation);
};

export const getOrganizations = async (apiBaseUrl: string, oAuthToken: OauthToken): Promise<Array<JDOrganization>> => {
  const response: GetOrganizationsResponse = await backendGet(`/organizations`, oAuthToken, apiBaseUrl);
  return response.values;
};

export const getOrganizationAssetsWithLocation = async (
  apiBaseUrl: string,
  oAuthToken: OauthToken,
  organizationId: string,
): Promise<JDAsset[]> => {
  const response: GetOrganizationAssetsWithLocationResponse = await backendGet(
    `/organizations/${organizationId}/assetsWithLocation`,
    oAuthToken,
    apiBaseUrl,
  );
  return response.values.map((asset: JDAssetRaw) => toAsset(asset));
};

export const deleteAssetById = async (apibaseUrl: string, oauthToken: OauthToken, assetId: string): Promise<any> => {
  return backendDelete(`/assets/${assetId}`, oauthToken, apibaseUrl);
};
