import ReactGA from "react-ga";
import { useContext, useEffect } from "react";
import ApplicationContext from "../contexts/ApplicationContext";

export const useGoogleAnalytics = () => {
  const { environmentVariables } = useContext(ApplicationContext);

  useEffect(() => {
    const isProduction = Boolean(
      environmentVariables && environmentVariables.environment === "prod"
    );
    const trackingId = "UA-452999-10";
    ReactGA.initialize(trackingId, { testMode: !isProduction });
    ReactGA.pageview("/home");
  }, [environmentVariables]);
};
