import clustersDbscan from '@turf/clusters-dbscan';
import { featureCollection } from '@turf/helpers';
import { JDAssetLocation } from '../../types';
import { toTurfPoints } from '../geo/geo-mappers';

export const getClusteredPoints = (assetLocationsForSingleDay: JDAssetLocation[], clusterRadius: number) => {
  const pointsInGroup = featureCollection(assetLocationsForSingleDay.map(toTurfPoints));

  return clustersDbscan(pointsInGroup, clusterRadius);
};
