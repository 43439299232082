import React from 'react';
import { makeStyles } from '@material-ui/core';
import mapTileProvider from '../../../../../services/mapTileService';
import Map from 'pigeon-maps';

const useStyles = makeStyles(theme => ({
  mapContainer: {
    'flex-grow': 1,
    width: '100%',
    height: '100%',
  },
  topText: {
    textAlign: 'center',
    padding: '8px',
    color: theme.palette.primary.main,
    fontSize: '17px',
  },
}));

const EmptyMap: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.topText}>No locations found</div>
      <div className={classes.mapContainer}>
        <Map center={[0, 0]} zoom={2} metawheelZoom={true} provider={mapTileProvider} />
      </div>
    </>
  );
};

export default EmptyMap;
