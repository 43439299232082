import { EnvironmentVariables } from '../types';

export const getAndSetEnvironmentVariables = async (
  setEnvironmentVariables: (environmentVariables: EnvironmentVariables) => void,
): Promise<void> => {
  const response = await fetch('/env.json');
  const json: EnvironmentVariables = await response.json();

  setEnvironmentVariables(json);
};
