import turfCenter from '@turf/center';
import * as turf from '@turf/helpers';
import { Feature } from '@turf/helpers';

export const getCentroid = (features: Array<Feature>) => {
  if (features.length === 1) {
    return features[0];
  }
  return turfCenter(turf.featureCollection(features));
};

export const toCentroidsForDate = (clusteredGroups: any, dateKey: string) => {
  return (accum2: any, groupNameKey: string) => {
    const featuresForDateForGroupName = clusteredGroups[dateKey][groupNameKey];
    accum2[groupNameKey] = getCentroid(featuresForDateForGroupName);
    return accum2;
  };
};

export function toCentroids(clusteredGroups: any) {
  return (accum: any, dateKey: string) => {
    const clusteredGroupForDate = clusteredGroups[dateKey];
    const centroidsForDate = Object.keys(clusteredGroupForDate).reduce(
      toCentroidsForDate(clusteredGroups, dateKey),
      {},
    );
    accum[dateKey] = centroidsForDate;
    return accum;
  };
}

export function getCentroids(clusteredGroups: any) {
  if (clusteredGroups) {
    const centroids: any = Object.keys(clusteredGroups).reduce(toCentroids(clusteredGroups), {});
    return centroids;
  }
}

export function getAndSetCentroids(clusteredGroups: any, setCentroids: Function) {
  if (clusteredGroups) {
    const centroids: any = Object.keys(clusteredGroups).reduce(toCentroids(clusteredGroups), {});
    setCentroids(centroids);
  }
}
