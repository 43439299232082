import React, { useContext } from 'react';
import { createStyles, FormControl, Typography, makeStyles, MenuItem, TextField } from '@material-ui/core';
import ApplicationContext from '../../../../contexts/ApplicationContext';
import { JDOrganization } from '../../../../types';
import Assets from './Assets';
import ReactGA from 'react-ga';
import { categories } from '../../../../google-analytics/constants';
import ProgressBar from './ProgressBar';

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      textAlign: 'center',
      display: 'grid',
      width: '100%',
      marginTop: '14px',
      marginBottom: '11px',
    },
    label: {
      fontSize: '12px',
      fontWeight: 'bold',
      textAlign: 'left',
      color: '#7c7c7c',
      textTransform: 'uppercase',
    },
    input: {
      textAlign: 'left',
      color: '#7c7c7c',
      borderColor: '#D0D0D0',
    },
    menuItem: {
      textAlign: 'left',
    },
  }),
);

const LeftSideContent: React.FC = () => {
  const { organizations, selectedOrganization, selectOrganization, totalOrganizations } = useContext(
    ApplicationContext,
  );

  const classes = useStyles();

  const renderOrganizationMenuItems = () => {
    return organizations?.map((organization: JDOrganization) => {
      return (
        <MenuItem key={organization.id} value={organization.id} className={classes.menuItem}>
          {organization.name}
        </MenuItem>
      );
    });
  };

  const onChangeHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
    ReactGA.event({
      category: categories.NavigateStay.analyticKey,
      action: categories.NavigateStay.actions.organizationListItem.analyticKey,
    });
    selectOrganization(organizations?.find(organization => organization.id === event.target.value));
  };

  if (organizations && organizations.length) {
    const value: number = selectedOrganization ? selectedOrganization.id : 0;
    return (
      <>
        <FormControl variant="outlined" className={classes.formControl}>
          <Typography className={classes.label} id="select-organization-label">
            Select Organization
          </Typography>
          <TextField
            select
            aria-labelledby="select-organization-label"
            value={value}
            onChange={onChangeHandler}
            variant="outlined"
            size="small"
            className={classes.input}
            InputProps={{
              className: classes.input,
            }}
          >
            <ProgressBar items={organizations} totalItems={totalOrganizations} />
            {renderOrganizationMenuItems()}
          </TextField>
        </FormControl>
        {selectedOrganization && <Assets />}
      </>
    );
  } else {
    return <></>;
  }
};

export default LeftSideContent;
