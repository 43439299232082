import React from 'react';
import DateRangePickerContainer from './DateRangePickerContainer';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '0 2%',
  },
});

const Toolbar: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.toolbar}>
      <DateRangePickerContainer />
    </div>
  );
};

export default Toolbar;
