import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Styles } from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

export const styles: Styles<Theme, {}> = {
  hide: {
    visibility: 'hidden',
  },
  progress: {
    marginTop: '5px',
  },
};

const useStyles = makeStyles(styles);
interface Props {
  items: any[];
  totalItems: number;
}

const ProgressBar: React.FC<Props> = props => {
  const { progress, hide } = useStyles();
  const { items, totalItems } = props;
  const normalise = (value = 0, max: number) => (value * 100) / max;
  const shouldBeHidden: boolean = totalItems === 0 || items?.length >= totalItems;
  const loadedItemsCount = items?.length || 0;
  const total = totalItems || 1;

  return (
    <LinearProgress
      className={`${progress} ${shouldBeHidden ? hide : ''}`}
      variant="determinate"
      value={normalise(loadedItemsCount, total)}
    />
  );
};

ProgressBar.propTypes = {
  items: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
};

export default ProgressBar;
