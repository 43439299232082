import { Grid, makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import CenterContent from './centerContent/CenterContent';
import LeftSideContent from './leftSideContent/LeftSideContent';
import RightSideContent from './rightSideContent/RightSideContent';
import Toolbar from '../toolbar/Toolbar';

const useStyles = makeStyles(() => ({
  leftSideContent: {
    maxWidth: '17rem',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  middleContent: {
    minHeight: '400px',
  },
  rightSideContent: {
    maxWidth: '17rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'column nowrap',
  },
  toolbar: {
    margin: '20px auto 0',
    maxWidth: 'calc(100vw - 6rem)',
    padding: '16px',
    borderRadius: '0',
    borderBottom: '1px solid #E2E0E0',
  },
  content: {
    height: 'calc(100vh - 321px)',
    padding: '16px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    margin: '32px auto',
    marginTop: '0',
    maxWidth: 'calc(100vw - 6rem)',
  },
}));

const LoggedInContent: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Paper elevation={0} className={classes.toolbar}>
        <Toolbar />
      </Paper>

      <Paper elevation={0} className={`${classes.content}`}>
        <Grid container justify="space-around" spacing={1}>
          <Grid item xs={10} sm={4} md={3} className={classes.leftSideContent}>
            <LeftSideContent />
          </Grid>
          <Grid container item xs={12} sm={8} md={6} className={classes.middleContent}>
            <CenterContent />
          </Grid>
          <Grid item xs={10} sm={10} md={3} className={classes.rightSideContent}>
            <RightSideContent />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default LoggedInContent;
