import { useContext, useEffect } from "react";
import ApplicationContext from "../contexts/ApplicationContext";
import { JDAssetLocation } from "../types";
import { generateClusters } from "../services/clusters/main";

export function getAndSetClusters(
  assetLocations: Array<JDAssetLocation> | null,
  clusterRadius: number,
  setClusteredGroups: Function,
  setSelectedClusteredGroupDate: Function
) {
  if (assetLocations && assetLocations.length) {
    const clusters = generateClusters(assetLocations, clusterRadius);
    setClusteredGroups(clusters);
    setSelectedClusteredGroupDate(Object.keys(clusters)[0]);
  }
}

export const useClusters = (clusterRadius: number) => {
  const {
    assetLocations,
    setClusteredGroups,
    setSelectedClusteredGroupDate
  } = useContext(ApplicationContext);

  useEffect(() => {
    getAndSetClusters(
      assetLocations,
      clusterRadius,
      setClusteredGroups,
      setSelectedClusteredGroupDate
    );
  }, [assetLocations, clusterRadius, setClusteredGroups, setSelectedClusteredGroupDate]);
};
