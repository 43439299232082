import React, { useContext } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { deleteAssetById } from '../../services/backend/backendService';
import { getAndSetAssets } from '../../hooks/Assets';
import ApplicationContext from '../../contexts/ApplicationContext';
import { clearSelectedAsset } from '../../contexts/ContextUtils';
import { JDAsset } from '../../types';
import { Box } from '@material-ui/core';
import { getAssetsForOrganization } from '../../services/organization/organizationStorageService';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DeleteAssetDialog() {
  const {
    selectedOrganization,
    organizationAssetStorage,
    setOrganizationAssetStorage,
    setFilteredAssets,
    environmentVariables,
    oauthToken,
    toBeDeletedAssetId,
    setToBeDeletedAssetId,
    selectAsset,
    setSelectedClusteredGroupDate,
    setSelectedClusteredGroupName,
    setClusteredGroups,
    setBucketMetadata,
    setCentroids,
    setCenterOfCentroids,
    setShowSplashScreen,
    setAssetsFetchInProgress,
  } = useContext(ApplicationContext);

  const { assets } = getAssetsForOrganization(selectedOrganization, organizationAssetStorage);

  const deleteAsset = async () => {
    if (environmentVariables && oauthToken && toBeDeletedAssetId) {
      await deleteAssetById(environmentVariables.apiBaseUrl, oauthToken, toBeDeletedAssetId);

      setToBeDeletedAssetId(null);

      clearSelectedAsset(
        selectAsset,
        setSelectedClusteredGroupDate,
        setSelectedClusteredGroupName,
        setClusteredGroups,
        setBucketMetadata,
        setCentroids,
        setCenterOfCentroids,
        setShowSplashScreen,
      );

      getAndSetAssets(
        selectedOrganization,
        oauthToken,
        environmentVariables,
        setOrganizationAssetStorage,
        organizationAssetStorage,
        setFilteredAssets,
        setAssetsFetchInProgress,
      );
    }
  };

  const getAssetName = () => {
    const assetToBeDeleted: JDAsset | undefined = assets?.find(asset => asset.id === toBeDeletedAssetId);

    return assetToBeDeleted?.title;
  };

  const handleClose = () => {
    setToBeDeletedAssetId(null);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={!!toBeDeletedAssetId}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Delete Asset Confirmation
      </DialogTitle>
      <DialogContent dividers>
        <Typography>
          <Box component="span">Are you sure you want to delete </Box>
          <Box fontWeight="fontWeightBold" component="span">
            {getAssetName()}
          </Box>
          ?
        </Typography>
        <Typography>This will delete the asset and all associated location data.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteAsset} color="secondary">
          Delete Asset
        </Button>
        <Button autoFocus onClick={handleClose} variant="contained" color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
