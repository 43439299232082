import { makeStyles, Typography, Link } from '@material-ui/core';
import React, { useContext } from 'react';
import ApplicationContext, { Scenes } from '../../../../contexts/ApplicationContext';
import AssetRecordingDates from './AssetRecordingDates';
import AllLocationsLegend from './legends/AllLocationsLegend';
import AssetsLastKnowLocationsLegend from './legends/AssetsLastKnownLocationsLegend';
import SingleLocationLegend from './legends/SingleLocationLegend';
import SingleLocationDetails from './SingleLocationDetails';
import DownloadCsvButton from './DownloadCsvButton';
import { getApplicationScene } from '../../../../contexts/ContextUtils';
import DateRangeDisclaimer from './DateRangeDisclaimer';

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  noLocations: {
    marginTop: '32px',
    marginBottom: '24px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  assetTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  rightSideDetails: {
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
  },
  spacer: {
    flex: '1',
  },
}));

const RightSideContent: React.FC = () => {
  const classes = useStyles();
  const {
    organizations,
    organizationAssetStorage,
    selectedOrganization,
    selectedAsset,
    clusteredGroups,
    centroids,
    centerOfCentroids,
    selectedClusteredGroupDate,
    selectedClusteredGroupName,
    showSplashScreen,
    assetLocations,
  } = useContext(ApplicationContext);
  let content: any;

  const SelectedAssetTitle: React.FC = () => {
    return <Typography className={classes.assetTitle}>{selectedAsset?.title || 'asset'}</Typography>;
  };

  const applicationScene: Scenes | null = getApplicationScene(
    organizations,
    organizationAssetStorage,
    selectedOrganization,
    selectedAsset,
    clusteredGroups,
    centroids,
    centerOfCentroids,
    selectedClusteredGroupName,
    selectedClusteredGroupDate,
    showSplashScreen,
    assetLocations,
  );

  switch (applicationScene) {
    case Scenes.AllAssetsScene:
      content = (
        <>
          <div className={classes.rightSideDetails} role="presentation">
            <Typography>To view more details about an individual asset please select one from the map.</Typography>
            <DownloadCsvButton />
          </div>
          <AssetsLastKnowLocationsLegend />
        </>
      );
      break;

    case Scenes.AllLocationsScene:
      content = (
        <>
          <div>
            <AssetRecordingDates />
            <DateRangeDisclaimer />
            <Typography variant={'h5'} className={classes.title}>
              Summary For:
            </Typography>
            <SelectedAssetTitle />
          </div>
          <AllLocationsLegend />
        </>
      );
      break;

    case Scenes.SingleLocationScene:
      content = (
        <>
          <div>
            <AssetRecordingDates />
            <DateRangeDisclaimer />
            <Typography variant={'h5'} className={classes.title}>
              Summary For:
            </Typography>
            <SelectedAssetTitle />
            <SingleLocationDetails />
          </div>
          <SingleLocationLegend />
        </>
      );
      break;

    case Scenes.NoAssetLocationsScene:
      content = (
        <>
          <Typography variant={'h5'} className={classes.noLocations}>
            No Locations<br></br>Found For:<br></br>
          </Typography>
          <SelectedAssetTitle />
          <DateRangeDisclaimer />
          <div className={classes.spacer}></div>
          <Typography>
            You can download the AgPoint app from{' '}
            <Link href={'https://play.google.com/store/apps/details?id=com.saithingfinder'}>Google Play</Link> or the{' '}
            <Link href={'https://itunes.apple.com/us/app/agpoint/id1405641916'}>Apple App Store</Link> to start tracking
            your asset locations.
          </Typography>
        </>
      );
      break;

    case Scenes.LoadingScene:
    default:
      content = <></>;
  }

  return content;
};

export default RightSideContent;
