import { useContext, useEffect } from "react";
import ApplicationContext from "../contexts/ApplicationContext";
import turfCenter from "@turf/center";
import * as turf from "@turf/helpers";
import { Feature } from "@turf/helpers";

const getCentroid = (features: Array<Feature>) => {
  if (features.length === 1) {
    return features[0];
  }
  return turfCenter(turf.featureCollection(features));
};

function getAndSetCenterOfClusterCentroids(
  centroids: any,
  selectedClusteredGroupDate: string | null,
  setCenterOfCentroids: Function
) {
  if (centroids && Object.keys(centroids) && selectedClusteredGroupDate) {
    const centroidsForDate = centroids[selectedClusteredGroupDate];
    if (centroidsForDate) {
      const listOfAllCentroidsForDate = Object.keys(centroidsForDate).reduce(
        (accum: any, groupNameKey: string) => {
          return accum.concat(centroidsForDate[groupNameKey]);
        },
        []
      );

      let centerOfCentroidsForDate = getCentroid(listOfAllCentroidsForDate);
      setCenterOfCentroids(centerOfCentroidsForDate);
    }
  }
}

export const useCenterOfCentroidsForDate = () => {
  const {
    selectedClusteredGroupDate,
    centroids,
    setCenterOfCentroids
  } = useContext(ApplicationContext);

  useEffect(() => {
    getAndSetCenterOfClusterCentroids(
      centroids,
      selectedClusteredGroupDate,
      setCenterOfCentroids
    );
  }, [centroids, selectedClusteredGroupDate, setCenterOfCentroids]);
};
