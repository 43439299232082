import { getReducerByGroupName } from './byGroupNameReducer';

export function getReducerByDate(clusteredGroups: any) {
  const toMetaDataByDate = (groupAccum: any, date: string) => {
    const dateGroup = clusteredGroups[date];

    const toMetaDataByGroupName = getReducerByGroupName(dateGroup);
    groupAccum[date] = (Object.keys(dateGroup) as Array<any>).reduce(toMetaDataByGroupName, {});

    return groupAccum;
  };
  return toMetaDataByDate;
}
