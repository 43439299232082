import { useContext, useEffect } from 'react';
import ApplicationContext from '../contexts/ApplicationContext';
import { getAndSetCentroids } from '../services/centroids/getCentroids';

export const useClusterCentroids = () => {
  const {
    clusteredGroups,
    selectedClusteredGroupDate,
    setCentroids
  } = useContext(ApplicationContext);

  useEffect(() => {
    getAndSetCentroids(clusteredGroups, setCentroids);
  }, [clusteredGroups, selectedClusteredGroupDate, setCentroids]);
};
