import * as turf from '@turf/helpers';
import { JDAssetLocation, TurfPoint } from '../../types';
import { Point } from 'geojson';

export function toTurfPoints(assetLocation: JDAssetLocation): TurfPoint {
  const assetLocationPoint: Point = (assetLocation.geometry.geometry as turf.GeometryCollection).geometries[0] as Point;
  return turf.point(assetLocationPoint.coordinates, {
    time: assetLocation.timestamp,
  });
}
