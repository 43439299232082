import { makeStyles, Link, Tooltip, Typography, Hidden } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import ApplicationContext from '../../../../contexts/ApplicationContext';
import ProgressBar from './ProgressBar';
import { FixedSizeList } from 'react-window';
import Asset from './Asset';
import ViewAllAssetsHeader from './ViewAllAssetsHeader';
import { getAssetsForOrganization } from '../../../../services/organization/organizationStorageService';
import { JDAsset } from '../../../../types';
import SearchBar from './SearchBar';

const useStyles = makeStyles(() => ({
  assetsTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  assetList: {
    overflow: 'auto',
  },
  centerText: {
    textAlign: 'center',
  },
}));

const Assets: React.FC = () => {
  const classes = useStyles();
  const [filteredAssets, setFilteredAssets] = useState<JDAsset[]>([]);
  const { organizationAssetStorage, selectedAsset, selectedOrganization, assetsFetchInProgress } = useContext(
    ApplicationContext,
  );

  const { assets, total } = getAssetsForOrganization(selectedOrganization, organizationAssetStorage);

  useEffect(() => {
    setFilteredAssets(assets);
  }, [selectedOrganization]);

  const RenderAsset = (props: any) => {
    const { index, style } = props;
    const asset: JDAsset | null = filteredAssets ? filteredAssets[index - 1] : null;
    const selectedAssetId = selectedAsset?.id ? selectedAsset.id : null;

    return <Asset asset={asset} style={style} selectedAssetId={selectedAssetId} />;
  };

  const renderRow = ({ index, style }: { index: number; style: any }) => {
    return <>{index === 0 ? <ViewAllAssetsHeader style={style} /> : <RenderAsset index={index} style={style} />}</>;
  };

  if (!assetsFetchInProgress && assets.length === 0) {
    return (
      <>
        <Typography variant="body1" className={classes.centerText}>
          Unable to find assets for your organization
        </Typography>
        <Typography variant="body2" className={classes.centerText}>
          If you believe this is in error, you may need to enable AgPoint access to the currently selected organization
          in{' '}
          <Link href="https://connections.deere.com/" target="_blank" rel="noopener noreferrer">
            John Deere Connections
          </Link>{' '}
          and reload this page
        </Typography>
      </>
    );
  }

  if (assets.length > 0) {
    return (
      <Tooltip title="Choose an Asset" aria-label="Choose an Asset">
        <>
          <Typography variant="h5" className={classes.assetsTitle}>
            Assets ({filteredAssets.length})
          </Typography>
          <ProgressBar items={assets} totalItems={total} />
          <SearchBar searchList={assets} searchKeyProperty={'title'} filteredContextSetter={setFilteredAssets} />
          <FixedSizeList
            height={260}
            width={'100%'}
            itemCount={filteredAssets?.length ? filteredAssets.length + 1 : 1}
            itemSize={48}
            className={classes.assetList}
          >
            {renderRow}
          </FixedSizeList>
        </>
      </Tooltip>
    );
  }

  return null;
};

export default Assets;
