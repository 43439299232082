import { JDAsset, JDAssetRaw, JDAssetLocationRaw } from '../../../types';
import { toAssetLocation } from './AssetLocation';

export const toAsset = (asset: JDAssetRaw): JDAsset => {
  return {
    ...asset,
    lastKnownLocation: asset.lastKnownLocation && toAssetLocation(asset.lastKnownLocation),
    locations:
      asset.locations && asset.locations.map((locationRaw: JDAssetLocationRaw) => toAssetLocation(locationRaw)),
  };
};
