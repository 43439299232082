import { IconButton, makeStyles } from '@material-ui/core';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Map from 'pigeon-maps';
import Overlay from 'pigeon-overlay';
import React, { useContext } from 'react';
import ApplicationContext from '../../../../../contexts/ApplicationContext';
import { getZoomLevel } from '../../../../../services/geo/zoom-level';
import ReactGA from 'react-ga';
import { categories } from '../../../../../google-analytics/constants';
import mapTileProvider from '../../../../../services/mapTileService';
import { getCoordinates } from '../../../../../services/geo/getCoordinates';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    'flex-grow': 1,
    width: '100%',
    height: '100%',
  },
  topLink: {
    textAlign: 'center',
    padding: '8px',
    color: theme.palette.primary.main,
    fontSize: '17px',
  },
}));

const AllLocationsMap: React.FC = () => {
  const classes = useStyles();
  const {
    selectedClusteredGroupDate,
    centerOfCentroids,
    centroids,
    setSelectedClusteredGroupName,
    setApplicationError,
  } = useContext(ApplicationContext);

  function getMarkerForGroup(groupNameKey: string) {
    if (groupNameKey.toLowerCase().includes('noise')) {
      return <LocalShippingIcon fontSize={'large'} />;
    }

    return <BlurCircularIcon fontSize={'large'} />;
  }

  function renderMarker(lat: number, lng: number, groupNamekey: string) {
    return (
      <Overlay anchor={[lat, lng]} offset={[20, 20]} key={lat + lng}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          size="medium"
          onClick={() => {
            if (groupNamekey.toLowerCase().includes('noise')) {
              ReactGA.event({
                category: categories.NavigateStay.analyticKey,
                action: categories.NavigateStay.actions.noiseMapButton.analyticKey,
              });
            } else {
              ReactGA.event({
                category: categories.NavigateStay.analyticKey,
                action: categories.NavigateStay.actions.clusterMapButton.analyticKey,
              });
            }
            setSelectedClusteredGroupName(groupNamekey);
          }}
        >
          {getMarkerForGroup(groupNamekey)}
        </IconButton>
      </Overlay>
    );
  }

  const isNotEdgePoint = (centroid: any): boolean => !centroid?.groupNamekey?.toLowerCase().includes('edge');

  function renderMarkers(centroidsForDate: any) {
    return (
      Object.keys(centroidsForDate).length &&
      Object.keys(centroidsForDate)
        .reduce((accum: any, groupNamekey: string) => {
          accum.push({
            feature: centroidsForDate[groupNamekey],
            groupNamekey,
          });
          return accum;
        }, [])
        .filter(isNotEdgePoint)
        .map((centroid: any) => {
          const coordinates = getCoordinates(centroid.feature);
          const lat = coordinates[0];
          const lng = coordinates[1];
          return renderMarker(lat, lng, centroid.groupNamekey);
        })
    );
  }

  function renderMap() {
    if (!centroids) {
      return;
    }

    const centroidsForDate = centroids[selectedClusteredGroupDate as string];

    if (centroidsForDate) {
      const centroidsForDateList: any[] = Object.keys(centroidsForDate).reduce((accum: any[], groupNameKey: string) => {
        return accum.concat(centroidsForDate[groupNameKey]);
      }, []);
      if (Object.keys(centroidsForDate) && centerOfCentroids) {
        return (
          <div className={classes.mapContainer}>
            <Map
              center={getCoordinates(centerOfCentroids)}
              zoom={getZoomLevel(centroidsForDateList)}
              metaWheelZoom={true}
              provider={mapTileProvider}
              key={Math.random()} // This forces a re-render when the rest of the props don't change
            >
              {renderMarkers(centroidsForDate)}
            </Map>
          </div>
        );
      }
    } else {
      setApplicationError({ message: 'centroidsForDate not defined' });
    }
  }

  return (
    <>
      <div className={classes.topLink}>Click a location group on the map for details</div>
      {renderMap()}
    </>
  );
};

export default AllLocationsMap;
