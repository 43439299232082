import React, { useContext, useEffect, useState } from 'react';
import { from, Observable } from 'rxjs';
import { Button, Tooltip, CircularProgress, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import ApplicationContext from '../../../../contexts/ApplicationContext';
import csvExport from '../../../../services/csvExport/csvExport';
import csvDownload from 'json-to-csv-export';

const useStyles = makeStyles({
  progress: {
    width: '20px',
    height: '20px',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    margin: 'auto',
  },
});

const DownloadCsvButton: React.FC = () => {
  const [exportData, setExportData] = useState(null);
  const [error, setError] = useState();
  const { selectedOrganization, oauthToken, environmentVariables } = useContext(ApplicationContext);
  const classes = useStyles();

  useEffect(() => {
    const subscription = (from(csvExport(selectedOrganization, oauthToken, environmentVariables)) as Observable<
      any
    >).subscribe(setExportData, setError);
    return () => subscription.unsubscribe();
  }, [selectedOrganization, oauthToken, environmentVariables]);

  if (error) {
    return (
      <Tooltip title="There was an error fetching the data.">
        <span>
          <Button variant="contained" disabled>
            Error
          </Button>
        </span>
      </Tooltip>
    );
  }

  const disabled: any = exportData == null;
  const icon: any = disabled ? <CircularProgress size="20" className={classes.progress} /> : <GetAppIcon />;
  const toolTipText: string = disabled
    ? 'Fetching the data for all the assets...'
    : 'Click here to download a CSV file version of the selected data.';

  return (
    <Tooltip title={toolTipText}>
      <span>
        <Button
          variant="contained"
          color="primary"
          onClick={() => csvDownload(exportData, 'data.csv')}
          disabled={disabled}
          endIcon={icon}
        >
          Download CSV
        </Button>
      </span>
    </Tooltip>
  );
};

export default DownloadCsvButton;
