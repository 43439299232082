import Map from 'pigeon-maps';
import Overlay from 'pigeon-overlay';
import { Card, IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import ApplicationContext from '../../../../../contexts/ApplicationContext';
import { JDAsset, TurfPoint } from '../../../../../types';
import * as turf from '@turf/helpers';
import turfCenter from '@turf/center';
import { toTurfPoints } from '../../../../../services/geo/geo-mappers';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import { getZoomLevel } from '../../../../../services/geo/zoom-level';
import ReactGA from 'react-ga';
import { categories } from '../../../../../google-analytics/constants';
import mapTileProvider from '../../../../../services/mapTileService';
import { getCoordinates } from '../../../../../services/geo/getCoordinates';
import { getAssetsForOrganization } from '../../../../../services/organization/organizationStorageService';

const useStyles = makeStyles(theme => ({
  mapContainer: {
    'flex-grow': 1,
    width: '100%',
    height: '100%',
  },
  topLink: {
    textAlign: 'center',
    padding: '8px',
    color: theme.palette.primary.main,
    fontSize: '17px',
  },
}));

const AssetsLastKnownLocationsMap: React.FC = () => {
  const classes = useStyles();
  const { organizationAssetStorage, selectedOrganization, selectAsset } = useContext(ApplicationContext);
  const { assets } = getAssetsForOrganization(selectedOrganization, organizationAssetStorage);

  const lastKnownLocationOfAllAssetsPoints: TurfPoint[] | null = assets
    .filter(asset => {
      return asset.lastKnownLocation;
    })
    .map(asset => {
      return asset.lastKnownLocation;
    })
    .map(toTurfPoints);

  const sourceAllies = [41.6434181, -93.7651512];

  const centerOfCluster = lastKnownLocationOfAllAssetsPoints
    ? getCoordinates(turfCenter(turf.featureCollection(lastKnownLocationOfAllAssetsPoints)))
    : sourceAllies;

  function renderMarker(lat: number, lng: number, asset: JDAsset, key: number) {
    return (
      <Overlay anchor={[lat, lng]} offset={[20, 20]} key={key}>
        <IconButton
          color="inherit"
          aria-label="menu"
          onClick={() => {
            ReactGA.event({
              category: categories.NavigateStay.analyticKey,
              action: categories.NavigateStay.actions.singleAssetMapButton.analyticKey,
            });
            selectAsset(asset);
          }}
        >
          <PhoneAndroidIcon />
        </IconButton>
        <Card>
          <Typography>{asset.title}</Typography>
        </Card>
      </Overlay>
    );
  }

  function renderMarkers() {
    return (
      assets &&
      assets
        .filter(asset => {
          return asset.lastKnownLocation;
        })
        .map((asset: JDAsset, index: number) => {
          const coordinates = getCoordinates(asset.lastKnownLocation.geometry as turf.Feature);
          const lat = coordinates[0];
          const lng = coordinates[1];
          return renderMarker(lat, lng, asset, index);
        })
    );
  }

  function renderMap() {
    const zoom = lastKnownLocationOfAllAssetsPoints ? getZoomLevel(lastKnownLocationOfAllAssetsPoints) : 16;
    return (
      <div className={classes.mapContainer}>
        <Map center={centerOfCluster} zoom={zoom} metaWheelZoom={true} provider={mapTileProvider}>
          {renderMarkers()}
        </Map>
      </div>
    );
  }

  return (
    <>
      <div className={classes.topLink}>Real Time Locations</div>
      {renderMap()}
    </>
  );
};

export default AssetsLastKnownLocationsMap;
