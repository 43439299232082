import { Feature, FeatureCollection, Point } from '@turf/helpers';
import { getClusterGroupName } from './cluster-namer';

export const groupByClusterName = (clusteredPoints: FeatureCollection<Point>) => {
  const features = clusteredPoints.features || [];

  return features.reduce((accum: any, singleFeaturePoint: Feature) => {
    const clusterGroupName = getClusterGroupName(singleFeaturePoint);
    const clusterGroup = accum[clusterGroupName] || [];

    clusterGroup.push(singleFeaturePoint);
    accum[clusterGroupName] = clusterGroup;

    return accum;
  }, {} as any);
};
