import { createMuiTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    verticalAlignCenter: {
      width: React.CSSProperties['width'];
      height: React.CSSProperties['height'];
      margin: React.CSSProperties['margin'];
      top: number;
      bottom: number;
    };
    verticalAlignCenterText: {
      display: React.CSSProperties['display'];
      height: React.CSSProperties['height'];
    };
  }
  interface ThemeOptions {
    verticalAlignCenter?: {
      width?: React.CSSProperties['width'];
      height?: React.CSSProperties['height'];
      margin?: React.CSSProperties['margin'];
      top?: number;
      bottom?: number;
    };
    verticalAlignCenterText?: {
      display?: React.CSSProperties['display'];
      height?: React.CSSProperties['height'];
    };
  }
}

const verticalAlignCenter = {
  margin: 'auto',
  top: 0,
  bottom: 0,
  height: '100%',
};

const verticalAlignCenterText = {
  display: 'flex',
  'flex-direction': 'row',
  'align-items': 'center',
  height: '100%',
};

const colors = {
  saiBlue: '#1a80c8',
  backgroundBlue: '#f0f5f8',
  warningRed: '#ff0033',
  jdGreen: '#20720d',
  jdYellow: '#fbd743',
};

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: colors.warningRed,
    },
    primary: {
      main: colors.saiBlue,
    },
  },
  verticalAlignCenter,
  verticalAlignCenterText,
});

export default theme;
