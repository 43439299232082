import { Grid, makeStyles, Typography } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import React from 'react';

const useStyles = makeStyles({
  legend: {
    width: '100%',
    padding: '24px',
  },
  legendText: {
    fontSize: 14,
  },
  icon: {
    padding: '8px',
  },
  label: {
    lineHeight: '20px',
  },
});

const SingleLocationLegend: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.legend} container justify={'center'} alignItems={'center'}>
      <Grid container item xs={6} justify={'center'} alignItems={'center'} direction={'row'}>
        <LocationOnIcon className={classes.icon} fontSize="small" />
        <Typography className={classes.label}>
          Central
          <br />
          Location
        </Typography>
      </Grid>
      <Grid container item xs={6} justify={'center'} alignItems={'center'} direction={'row'}>
        <FiberManualRecordIcon className={classes.icon} fontSize="small" />
        <Typography className={classes.label}>
          Recorded
          <br />
          Location
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SingleLocationLegend;
