import { IconButton, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { JDAsset } from '../../../../types';
import ApplicationContext from '../../../../contexts/ApplicationContext';
import ReactGA from 'react-ga';
import { categories } from '../../../../google-analytics/constants';
import PropTypes from 'prop-types';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

interface Props {
  asset: JDAsset | null;
  style: any;
  selectedAssetId: string | null;
}

const useStyles = makeStyles(theme => ({
  listItem: {
    '&:hover': {
      backgroundColor: '#f0f5f8 !important',
    },
  },
  listItemSelected: {
    backgroundColor: '#f0f5f8 !important',
    color: theme.palette.primary.main,
  },
  listItemText: {
    paddingLeft: '8px',
  },
}));

const Asset: React.FC<Props> = props => {
  const classes = useStyles();
  const { asset, style, selectedAssetId } = props;
  const { selectAsset, setShowSplashScreen, setToBeDeletedAssetId } = useContext(ApplicationContext);
  const isSelectedAsset = () => asset?.id === selectedAssetId;

  return (
    <ListItem
      style={style}
      button
      onClick={() => {
        if (isSelectedAsset()) {
          return;
        }
        selectAsset(asset);
        ReactGA.event({
          category: categories.NavigateStay.analyticKey,
          action: categories.NavigateStay.actions.singleAssetListItem.analyticKey,
        });
        setShowSplashScreen(false);
      }}
      selected={isSelectedAsset()}
      key={asset?.id}
      aria-label={asset?.title}
    >
      <PhoneAndroidIcon fontSize="small" />
      <ListItemText primary={asset?.title} className={classes.listItemText} />
      {isSelectedAsset() && (
        <IconButton
          aria-label={`Delete ${asset?.title}`}
          onClick={() => {
            setToBeDeletedAssetId(asset?.id);
          }}
        >
          <DeleteForeverIcon />
        </IconButton>
      )}
    </ListItem>
  );
};

Asset.propTypes = {
  asset: PropTypes.any.isRequired,
  style: PropTypes.any.isRequired,
  selectedAssetId: PropTypes.string,
};

export default Asset;
