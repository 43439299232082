import { Button, makeStyles, Tooltip } from '@material-ui/core';
import React, { useContext } from 'react';
import { getOauthToken } from '../../services/oauth/authService';
import ApplicationContext from '../../contexts/ApplicationContext';
import ReactGA from 'react-ga';
import { categories } from '../../google-analytics/constants';
import { EnvironmentVariables } from '../../types';
import { localStorage } from '../../globals/localStorage';

const useStyles = makeStyles({
  button: {
    height: '100%',
  },
});

interface LoginButtonProps {
  isLoggedIn: boolean;
}

const LoginButton: React.FC<LoginButtonProps> = ({ isLoggedIn }: LoginButtonProps) => {
  const classes = useStyles();
  const environmentVariables = useContext(ApplicationContext).environmentVariables as EnvironmentVariables;

  const login = async (): Promise<void> => {
    await getOauthToken(
      environmentVariables.uiFrontendUrl,
      environmentVariables.awsProxyUrl
    );
  };

  const loginClickFunction = () => {
    localStorage.clear();
    ReactGA.event({
      category: categories.Authentication.analyticKey,
      action: categories.Authentication.actions.login.analyticKey,
    });
    return login();
  };

  const logoutClickFunction = () => {
    ReactGA.event({
      category: categories.Authentication.analyticKey,
      action: categories.Authentication.actions.logout.analyticKey,
    });
    localStorage.clear();
    window.location.href = `https://my.deere.com/logout`;
  };

  return (
    <Tooltip title="This will redirect you to my.johndeere.com">
      <Button
        className={classes.button}
        color="inherit"
        onClick={isLoggedIn ? logoutClickFunction : loginClickFunction}
      >
        {isLoggedIn ? 'Logout' : 'Login'}
      </Button>
    </Tooltip>
  );
};

export default LoginButton;
