import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';
import { DateRangePicker } from 'react-dates';
import React, { useContext, useState, useEffect } from 'react';
import * as moment from 'moment';
import ApplicationContext from '../../../contexts/ApplicationContext';
import { clearSelectedAssetLocations } from '../../../contexts/ContextUtils';
import { DateRange } from '../../../types';

const DateRangePickerContainer: React.FC = () => {
  const {
    dateRange,
    setDateRange,
    setSelectedClusteredGroupDate,
    setSelectedClusteredGroupName,
    setClusteredGroups,
    setBucketMetadata,
    setCentroids,
    setCenterOfCentroids,
  } = useContext(ApplicationContext);

  const [startDate, setStartDate] = useState<moment.Moment | null>(moment.default().subtract(30, 'd').startOf('day'));
  const [endDate, setEndDate] = useState<moment.Moment | null>(moment.default().endOf('day'));
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);

  useEffect(() => {
    if (!dateRange) {
      setDateRange({ startDate, endDate });
    }
  });

  const handleDatesChange = ({ startDate, endDate }: DateRange) => {
    const newStartDate = startDate ? startDate.startOf('day') : null;
    const newEndDate = endDate ? endDate.endOf('day') : null;
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const setNewDateRangeInContext = ({ startDate, endDate }: DateRange) => {
    if (startDate?.isSame(dateRange?.startDate) && endDate?.isSame(dateRange?.endDate)) {
      return;
    }

    const startDateCopy: moment.Moment = moment.default(startDate).startOf('day');
    const endDateCopy: moment.Moment | null = endDate ? moment.default(endDate).endOf('day') : null;

    setDateRange({
      startDate: startDateCopy,
      endDate: endDateCopy,
    });

    clearSelectedAssetLocations(
      setSelectedClusteredGroupDate,
      setSelectedClusteredGroupName,
      setClusteredGroups,
      setBucketMetadata,
      setCentroids,
      setCenterOfCentroids,
    );
  };

  const isFutureDate = (date: moment.Moment) => {
    date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    return date.isSameOrAfter(moment.default());
  };

  const getLastMonth = () => {
    return moment.default().subtract(1, 'month');
  };

  return (
    <DateRangePicker
      startDate={startDate}
      startDateId="startDateId"
      endDate={endDate}
      endDateId="endDateId"
      onDatesChange={handleDatesChange}
      focusedInput={focusedInput}
      onFocusChange={setFocusedInput}
      minimumNights={0}
      numberOfMonths={1}
      onClose={setNewDateRangeInContext}
      isOutsideRange={isFutureDate}
      initialVisibleMonth={getLastMonth}
      hideKeyboardShortcutsPanel={true}
    />
  );
};

export default DateRangePickerContainer;
