import { useContext, useEffect } from 'react';
import ApplicationContext from '../contexts/ApplicationContext';
import {
  AssetsServiceResponse,
  EnvironmentVariables,
  JDAsset,
  JDOrganization,
  OauthToken,
  OrganizationAssetStorage,
} from '../types';
import { fetchAssetsForOrganization } from '../services/assets/assetsService';

export const getAndSetAssets = async (
  selectedOrganization: JDOrganization | null,
  oauthToken: OauthToken | null,
  environmentVariables: EnvironmentVariables | null,
  setOrganizationAssetStorage: (organizationAssetStorage: OrganizationAssetStorage) => void,
  organizationAssetStorage: OrganizationAssetStorage,
  setFilteredAssets: (assets: JDAsset[]) => void,
  setAssetsFetchInProgress: (inProgress: boolean) => void,
): Promise<void> => {
  if (!(selectedOrganization && oauthToken && environmentVariables)) {
    return;
  }

  setAssetsFetchInProgress(true);

  const url = `https://partnerapi.deere.com/platform/organizations/${selectedOrganization.id}/assets;count=50?embed=lastKnownLocation`;

  let initialResponse: AssetsServiceResponse;

  try {
    initialResponse = await fetchAssetsForOrganization(url, environmentVariables.awsProxyUrl, oauthToken);
  } catch (error) {
    return;
  } finally {
    setAssetsFetchInProgress(false);
  }

  if (
    selectedOrganization?.id &&
    organizationAssetStorage &&
    organizationAssetStorage[selectedOrganization.id] &&
    organizationAssetStorage[selectedOrganization.id].total === initialResponse.total
  ) {
    return;
  }

  const assetStorage: OrganizationAssetStorage = {
    ...organizationAssetStorage,
    [selectedOrganization.id]: {
      assets: initialResponse.assets,
      total: initialResponse.total,
    },
  };
  setFilteredAssets(initialResponse.assets);
  setOrganizationAssetStorage(assetStorage);

  let nextPageLink = initialResponse.nextPageLink;
  while (nextPageLink) {
    const nextPageResponse: AssetsServiceResponse = await fetchAssetsForOrganization(nextPageLink, environmentVariables.awsProxyUrl, oauthToken);
    nextPageResponse.assets.forEach(asset => assetStorage[selectedOrganization.id].assets.push(asset));
    setFilteredAssets(nextPageResponse.assets);
    setOrganizationAssetStorage(assetStorage);
    nextPageLink = nextPageResponse.nextPageLink;
  }
};

export const useAssets = (): void => {
  const {
    selectedOrganization,
    oauthToken,
    setOrganizationAssetStorage,
    organizationAssetStorage,
    setFilteredAssets,
    environmentVariables,
    setAssetsFetchInProgress,
  } = useContext(ApplicationContext);

  useEffect(() => {
    getAndSetAssets(
      selectedOrganization,
      oauthToken,
      environmentVariables,
      setOrganizationAssetStorage,
      organizationAssetStorage,
      setFilteredAssets,
      setAssetsFetchInProgress,
    );
  }, [
    selectedOrganization,
    oauthToken,
    setOrganizationAssetStorage,
    organizationAssetStorage,
    environmentVariables,
    setFilteredAssets,
    setAssetsFetchInProgress,
  ]);
};
