import { Button } from '@material-ui/core';
import React from 'react';
import GooglePlayStoreBadge from '../../../assets/play-store-badge.png';
import ReactGA from 'react-ga';
import { categories } from '../../../google-analytics/constants';

const PlayStoreBadge: React.FC = () => {
  return (
    <Button
      variant="text"
      disableFocusRipple={true}
      disableRipple={true}
      onClick={() => {
        ReactGA.event({
          category: categories.NavigateAway.analyticKey,
          action: categories.NavigateAway.actions.googlePlayStore.analyticKey,
        });
        window.open('https://play.google.com/store/apps/details?id=com.saithingfinder', '_blank');
      }}
      style={{ backgroundColor: 'transparent', padding: 0, margin: '17px 10px 0 0' }}
    >
      <img src={GooglePlayStoreBadge} alt="Google Play Store" height={40} width={133.5} />
    </Button>
  );
};

export default PlayStoreBadge;
