import { useContext, useEffect } from 'react';
import ApplicationContext from '../contexts/ApplicationContext';
import { getAddress } from '../services/addresses/getAddress';
import { ClusterDateGroup } from '../types';

export const getAndSetAddress = async (
  centroids: ClusterDateGroup,
  selectedClusteredGroupDate: string | null,
  selectedClusteredGroupName: string | null,
  setAddress: Function,
) => {
  const address = await getAddress(centroids, selectedClusteredGroupDate, selectedClusteredGroupName);
  if (address) {
    setAddress(address);
  }
};

export const useAddressLocationLookup = (setAddress: Function) => {
  const { bucketMetadata, selectedClusteredGroupName, selectedClusteredGroupDate, centroids } = useContext(
    ApplicationContext,
  );

  useEffect(() => {
    getAndSetAddress(centroids, selectedClusteredGroupDate, selectedClusteredGroupName, setAddress);
  }, [bucketMetadata, selectedClusteredGroupName, selectedClusteredGroupDate, centroids, setAddress]);
};
