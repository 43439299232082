import { FirstLegToken, ThirdLegToken, AccessToken } from '../../types';

export const FIRST_LEG_TOKEN = 'firstLegToken';
export const SECOND_LEG_TOKEN = 'secondLegToken';
export const THIRD_LEG_TOKEN = 'thirdLegToken';
export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';

export const getFirstLegToken = (): FirstLegToken => {
  const token = window.localStorage.getItem(FIRST_LEG_TOKEN);
  return token && JSON.parse(token);
};

export const setFirstLegToken = (token: FirstLegToken) => {
  return window.localStorage.setItem(FIRST_LEG_TOKEN, JSON.stringify(token));
};

export const getSecondLegToken = (): string | null => {
  return window.localStorage.getItem(SECOND_LEG_TOKEN);
};

export const setSecondLegToken = (token: string) => {
  return window.localStorage.setItem(SECOND_LEG_TOKEN, token);
};

export const getThirdLegToken = (): ThirdLegToken => {
  const token = window.localStorage.getItem(THIRD_LEG_TOKEN);
  return token && JSON.parse(token);
};

export const setThirdLegToken = (token: ThirdLegToken) => {
  return window.localStorage.setItem(THIRD_LEG_TOKEN, JSON.stringify(token));
};

export const getAccessToken = (): AccessToken => {
  const token = window.localStorage.getItem(ACCESS_TOKEN);
  return token && JSON.parse(token);
};

export const setAccessToken = (token: AccessToken) => {
  return window.localStorage.setItem(ACCESS_TOKEN, JSON.stringify(token));
};

export const getRefreshToken = (): string | null => {
  const token = window.localStorage.getItem(REFRESH_TOKEN);
  return token;
};

export const setRefreshToken = (token: string) => {
  return window.localStorage.setItem(REFRESH_TOKEN, token);
};
