import { Grid, makeStyles, Typography } from '@material-ui/core';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import React from 'react';

const useStyles = makeStyles({
  legend: {
    width: '100%',
    padding: '24px',
  },
  icon: {
    padding: '8px',
  },
  label: {
    lineHeight: '20px',
  },
});

const AllLocationsLegend: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.legend} container justify={'center'} alignItems={'center'}>
      <Grid container item xs={6} justify={'center'} alignItems={'center'} direction={'row'}>
        <LocalShippingIcon fontSize="small" className={classes.icon} />
        <Typography className={classes.label}>
          Driving/
          <br />
          Movement
        </Typography>
      </Grid>
      <Grid container item xs={6} justify={'center'} alignItems={'center'} direction={'row'}>
        <BlurCircularIcon fontSize="small" className={classes.icon} />
        <Typography className={classes.label}>
          Location
          <br />
          Group
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AllLocationsLegend;
