import { useContext, useEffect } from 'react';
import { fetchOrganizations } from 'src/services/organizations/organizationsService';
import ApplicationContext from '../contexts/ApplicationContext';
import { EnvironmentVariables, JDOrganization, OauthToken, OrganizationServiceResponse } from '../types';

export const getAndSetOrganizations = async (
  environmentVariables: EnvironmentVariables | null,
  oauthToken: OauthToken | null,
  organizations: Array<JDOrganization> | null,
  setOrganizations: (organizations: Array<JDOrganization>) => void,
  setSelectedOrganization: (organization: JDOrganization) => void,
  totalOrganizations: number,
  setTotalOrganizations: (totalOrganizations: number) => void,
): Promise<void> => {
  if (!(oauthToken && environmentVariables) || totalOrganizations) {
    return;
  }

  const url = `platform/organizations`;
  const initialResponse = await fetchOrganizations(url, environmentVariables.awsProxyUrl, oauthToken);
  setTotalOrganizations(initialResponse.total);
  setOrganizations(initialResponse.organizations);

  organizations = initialResponse.organizations;
  organizations.length && setSelectedOrganization(organizations[0]);

  let nextPageLink = initialResponse.nextPageLink;
  while (nextPageLink) {
    const nextPageResponse: OrganizationServiceResponse = await fetchOrganizations(nextPageLink, environmentVariables.awsProxyUrl, oauthToken);
    if (organizations) {
      setOrganizations([...organizations, ...nextPageResponse.organizations]);
    }
    nextPageLink = nextPageResponse.nextPageLink;
  }
};

export const useOrganizations = (): void => {
  const {
    environmentVariables,
    oauthToken,
    organizations,
    setOrganizations,
    setSelectedOrganization,
    totalOrganizations,
    setTotalOrganizations,
  } = useContext(ApplicationContext);

  useEffect(() => {
    if (oauthToken != undefined && environmentVariables != undefined) {
      getAndSetOrganizations(
        environmentVariables,
        oauthToken,
        organizations,
        setOrganizations,
        setSelectedOrganization,
        totalOrganizations,
        setTotalOrganizations,
      );
    }
  }, [
    environmentVariables,
    oauthToken,
    organizations,
    setOrganizations,
    setSelectedOrganization,
    totalOrganizations,
    setTotalOrganizations,
  ]);
};
