import { useContext, useEffect } from 'react';
import ApplicationContext from '../contexts/ApplicationContext';
import { getAndSetEnvironmentVariables } from './GetAndSetEnvironmentVariables'

export const useEnvironmentVariables = () => {
  const { setEnvironmentVariables } = useContext(ApplicationContext);

  useEffect(() => {
    getAndSetEnvironmentVariables(setEnvironmentVariables);
  }, [setEnvironmentVariables]);
};
