import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import WorldMap from '../../../assets/world-map.png';

const useStyles = makeStyles({
  card: {
    padding: '5px',
    marginBottom: '10px',
  },
  text: {
    marginTop: '5px',
  },
  title: {
    margin: '8px',
    color: '#0D6F92',
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '40px',
  },
  sidePadding: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  welcomeText: {
    fontSize: '18px',
    lineHeight: '32px',
  },
  mapWrapper: {
    margin: '0px',
    padding: '0px',
    height: '100%',
    width: '100%',
  },
  mapImage: {
    width: '100%',
    height: '100%',
  },
  content: {
    height: 'calc(100vh - 237px)',
    padding: '16px',
    overflow: 'scroll',
    margin: '32px auto',
    marginTop: '0',
    maxWidth: 'calc(100vw - 6rem)',
    paddingTop: 0,
  },
});

const LoggedOutContent: React.FC = () => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={`${classes.content}`}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography variant={'h4'} className={classes.title}>
            Welcome To AgPoint
          </Typography>
        </Grid>
        <Grid item xs={8} md={5} className={classes.sidePadding}>
          <Box boxShadow={2} className={classes.mapWrapper}>
            <img src={WorldMap} className={classes.mapImage} alt={''} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className={classes.sidePadding}>
          <Typography variant={'body1'} className={classes.welcomeText} paragraph={true}>
            AgPoint is a mobile application built by Source Allies that leverages John Deere APIs to track an
            organization's asset locations.
          </Typography>
          <Typography variant={'body1'} className={classes.welcomeText} paragraph={true}>
            In order to use AgPoint, you must already have a MyJohnDeere account. If you don't already have an account,
            you can create one before logging in. Once you have an account, you can download the AgPoint app from Google
            Play or the Apple App Store and start tracking your asset locations.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LoggedOutContent;
