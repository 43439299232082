import { GeoJSON, GeoJsonProperties } from "geojson";
import { Feature } from "@turf/helpers";
import { FIVE_MINUTES_IN_MILLIS } from "../time/timeConstants";
import { getTimeDifference } from "./timeDifference";

export const toCoLinearTimeGroups = (
  timeGroups: Array<Array<GeoJSON.Feature>>,
  feature: GeoJSON.Feature
) => {
  let newTimeGroups: Array<Array<GeoJSON.Feature>> = [];
  newTimeGroups = newTimeGroups.concat(timeGroups);

  if (newTimeGroups.length) {
    const lastTimeGroup: GeoJSON.Feature[] =
      newTimeGroups[newTimeGroups.length - 1];
    const lastItemInLastTimeGroup: Feature<GeoJsonProperties> =
      lastTimeGroup[lastTimeGroup.length - 1];

    const difference = getTimeDifference(lastItemInLastTimeGroup, feature);

    if (difference > FIVE_MINUTES_IN_MILLIS) {
      newTimeGroups.push([feature]);
    } else {
      lastTimeGroup.push(feature);
    }
  } else {
    newTimeGroups.push([feature]);
  }

  return newTimeGroups;
};
