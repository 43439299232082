import * as React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    width: '100%',
    textAlign: 'center',
    marginTop: '2%',
  },
});

const SplashScreen: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h1>Welcome To AgPoint</h1>
      <h3>Choose an organization and an asset to get started</h3>
    </div>
  );
};

export default SplashScreen;
