import { Feature } from "@turf/helpers";
import { GeoJsonProperties } from "geojson";

export const getSignedTimeDifference = (
  featureOne: Feature<GeoJsonProperties>,
  featureTwo: Feature<GeoJsonProperties>
) => {
  return (
    new Date(featureOne.properties?.time).getTime() -
    new Date(featureTwo.properties?.time).getTime()
  );
};

export const getTimeDifference = (
  featureOne: Feature<GeoJsonProperties>,
  featureTwo: Feature<GeoJsonProperties>
) => {
  return Math.abs(getSignedTimeDifference(featureOne, featureTwo));
};
