import {getAuthorizeUrl} from '../../services/oauth/getURLs';
import * as localStorageService from '../../services/localStorage/localStorageService';

export const getAccessToken = async (uiFrontendUrl: string, awsProxyUrl: string): Promise<void> => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code") as string;
    const state = params.get("state") as string;
    const token_string = getAuthorizeUrl(uiFrontendUrl, awsProxyUrl, code, state);
    console.log(token_string)
    const res = await fetch(token_string, {
        mode: 'cors',
        method: 'GET',
        headers: {}
    });

    const json_result = Object(await res.json());
    const tokenobj = {
        'accessToken': json_result.access_token,
        'refreshToken': json_result.refresh_token,
    }
    localStorageService.setAccessToken(tokenobj);
    localStorageService.setRefreshToken(json_result.refresh_token);
    window.location.href = uiFrontendUrl;
}
