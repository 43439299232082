import { makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import ApplicationContext from '../../../../contexts/ApplicationContext';
import { useAddressLocationLookup } from '../../../../hooks/AddressLookUp';
import { Address } from '../../../../types';

interface Styles {
  timeText: string;
  addressText: string;
  addressHeader: string;
  timeHeader: string;
}

const useStyles = makeStyles(() => ({
  timeText: {
    'text-align': 'center',
  },
  addressText: {
    'text-align': 'center',
  },
  addressHeader: {
    'margin-top': '10px',
    'font-weight': 'bold',
    'text-align': 'center',
  },
  timeHeader: {
    'margin-top': '10px',
    'font-weight': 'bold',
    'text-align': 'center',
  },
}));

function renderAddress(classes: Styles, address: Address) {
  return (
    <>
      <Typography variant={'h6'} className={classes.addressHeader}>
        Approximate Address:
      </Typography>

      {address.locationName && <Typography className={classes.addressText}>{address.locationName}</Typography>}

      <Typography className={classes.addressText}>
        {address.house_number && <span> {`${address.house_number} `}</span>}
        {address.road && <span>{`${address.road}`}</span>}
      </Typography>
      <Typography className={classes.addressText}>
        {address.city && <span>{`${address.city}, `}</span>}
        {address.state && <span>{`${address.state} `}</span>}
        {address.postcode && <span>{`${address.postcode}`}</span>}
      </Typography>
    </>
  );
}

function summarizeTimeSpent(metadataForDateForGroupName: any) {
  return Object.keys(metadataForDateForGroupName).reduce((sum: number, key: string) => {
    return sum + metadataForDateForGroupName[key].timeSpent;
  }, 0);
}

const SingleLocationDetails: React.FC = () => {
  const classes: Styles = (useStyles() as unknown) as Styles;
  const [address, setAddress] = useState<Address | undefined>(undefined);
  const { bucketMetadata, selectedClusteredGroupName, selectedClusteredGroupDate } = useContext(ApplicationContext);

  useAddressLocationLookup(setAddress);

  const metaForDateForGroupName =
    bucketMetadata && selectedClusteredGroupDate && selectedClusteredGroupName
      ? bucketMetadata[selectedClusteredGroupDate][selectedClusteredGroupName]
      : {};

  const timeSpent = summarizeTimeSpent(metaForDateForGroupName);

  const hours = timeSpent > 0 ? Math.floor(timeSpent / 60) : 0;
  const minutes = timeSpent > 0 ? Math.floor(timeSpent % 60) : 0;

  return (
    <>
      {selectedClusteredGroupDate && selectedClusteredGroupName && bucketMetadata && (
        <>
          <Typography variant={'h6'} className={classes.timeHeader}>
            Time Spent:
          </Typography>
          <Typography className={classes.timeText}>
            {hours} hours {minutes} minutes
          </Typography>
        </>
      )}
      {address && renderAddress(classes, address)}
    </>
  );
};

export default SingleLocationDetails;
