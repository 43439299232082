import { GeoJSON } from "geojson";
import { toCoLinearTimeGroups } from "./toCoLinearTimeGroupsReducer";
import { toTimeSpent } from "./toTimeSpentReducer";
import { getSignedTimeDifference } from "./timeDifference";

export function getReducerByGroupName(dateGroup: any) {
  const toMetaDataByGroupName = (
    clusterAcc: any,
    clusterName: string
  ): any[] => {
    const cluster: GeoJSON.Feature[] = dateGroup[clusterName];

    clusterAcc[clusterName] = cluster
      .sort(getSignedTimeDifference)
      .reduce(toCoLinearTimeGroups, [])
      .reduce(toTimeSpent, []);

    return clusterAcc;
  };
  return toMetaDataByGroupName;
}
