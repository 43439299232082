import { OrganizationServiceResponse, OauthToken, GetOrganizationsResponse } from '../../types';

export const fetchOrganizations = async (platformUrl: string, awsProxyUrl: string, oAuthToken: OauthToken): Promise<OrganizationServiceResponse> => {
  const encodedAccessToken = await encodeURIComponent(oAuthToken.accessToken);

  let responseBody: GetOrganizationsResponse;
  try {
    const response: Response = await fetch(`${awsProxyUrl}/api?endpoint=${encodeURIComponent(platformUrl)}`, {
        mode: 'cors',
        method: 'GET',
        headers: {
            "Accept": "application/vnd.deere.axiom.v3+json",
            "Authorization": `Bearer ${encodedAccessToken}`
        }
        });
    responseBody = await response.json();
  } catch (e) {
    throw new Error(e.message);
  }
  const { links, values, total } = responseBody;


  let nextLink = null;
  if (links) {
    nextLink = links.filter((link: any) => link.rel === 'nextPage')[0]?.uri;
  }

  return {
    organizations: values,
    nextPageLink: nextLink,
    total: total,
  };

};
