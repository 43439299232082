import {
  FormControl,
  Typography,
  makeStyles,
  MenuItem,
  Select,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import React, { useContext } from 'react';
import ApplicationContext from '../../../../contexts/ApplicationContext';
import ReactGA from 'react-ga';
import { categories } from '../../../../google-analytics/constants';
import KeyBoardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

const useStyles = makeStyles({
  label: {
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#7c7c7c',
  },
  formControl: {
    textAlign: 'center',
    display: 'grid',
    width: '100%',
    marginTop: '32px',
    marginBottom: '24px',
  },
  input: {
    padding: '14px',
    textAlign: 'left',
    color: '#7c7c7c',
  },
  menuItem: {
    textAlign: 'left',
  },
  notchedOutline: {
    border: '2px solid #D0D0D0',
  },
});

const separator = '---';
const AssetRecordingDates: React.FC = () => {
  const { clusteredGroups, selectedClusteredGroupDate, selectClusteredGroupDate } = useContext(ApplicationContext);
  const classes = useStyles();

  const renderAssetRecordingDateMenuItems = () => {
    return Object.keys(clusteredGroups).map((groupDate, index) => {
      return (
        <MenuItem key={groupDate} value={index + separator + groupDate} className={classes.menuItem}>
          {groupDate}
        </MenuItem>
      );
    });
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const [index, date] = (event.target.value as string).split(separator);

    ReactGA.event({
      category: categories.NavigateStay.analyticKey,
      action: categories.NavigateStay.actions.dateChooser.analyticKey,
      value: parseInt(index),
    });
    selectClusteredGroupDate(date);
  };

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <Typography className={classes.label} id="select-date-label">
        SELECT DATE
      </Typography>
      <Select
        aria-labelledby="select-date-label"
        value={
          selectedClusteredGroupDate
            ? Object.keys(clusteredGroups).indexOf(selectedClusteredGroupDate) + separator + selectedClusteredGroupDate
            : 0
        }
        onChange={handleChange}
        input={
          <OutlinedInput
            classes={{
              input: classes.input,
              notchedOutline: classes.notchedOutline,
            }}
            labelWidth={0}
            startAdornment={
              <InputAdornment position="start">
                <CalendarTodayIcon color="primary" />
              </InputAdornment>
            }
          />
        }
        IconComponent={KeyBoardArrowDownIcon}
      >
        {renderAssetRecordingDateMenuItems()}
      </Select>
    </FormControl>
  );
};

export default AssetRecordingDates;
