import { Button, Card, makeStyles, Modal } from '@material-ui/core';
import React, { useContext } from 'react';
import ApplicationContext from '../../contexts/ApplicationContext';
import LoggedOutContent from '../body/content/LoggedOutContent';

const useStyles = makeStyles({
  modal: {
    padding: 'auto',
    paddingTop: '20px',
    'margin-top': '10vh',
    'margin-left': 'auto',
    'margin-right': 'auto',
    outline: 'none',
    width: '50%',
    background: '#ddd',
  },
  paper: {
    background: '#ddd',
    display: 'block',
    'text-align': 'center',
    paddingTop: '20px',
  },
  closeButton: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    marginBottom: '20px',
  },
});

const InfoModal: React.FC = () => {
  const classes = useStyles();
  const { infoModalState, setInfoModalState } = useContext(ApplicationContext);

  const closeDialog = () => {
    setInfoModalState(false);
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={infoModalState}
      onClose={closeDialog}
    >
      <Card className={classes.modal}>
        <LoggedOutContent />
        <Button className={classes.closeButton} color="primary" variant="contained" onClick={closeDialog}>
          Close
        </Button>
      </Card>
    </Modal>
  );
};

export default InfoModal;
