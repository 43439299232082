import Map from 'pigeon-maps';
import Overlay from 'pigeon-overlay';
import { makeStyles, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import ApplicationContext from '../../../../../contexts/ApplicationContext';
import * as turf from '@turf/helpers';
import turfCenter from '@turf/center';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecord';
import RoomTwoToneIcon from '@material-ui/icons/RoomTwoTone';
import { getZoomLevel } from '../../../../../services/geo/zoom-level';
import ReactGA from 'react-ga';
import { categories } from '../../../../../google-analytics/constants';
import mapTileProvider from '../../../../../services/mapTileService';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getCoordinates } from '../../../../../services/geo/getCoordinates';

const useStyles = makeStyles(theme => ({
  mapContainer: {
    'flex-grow': 1,
    width: '100%',
    height: '100%',
  },
  topLink: {
    color: theme.palette.primary.main,
    fontSize: '17px',
    cursor: 'pointer',
    paddingBottom: '3px',
  },
  icon: {
    width: '14px',
    height: '14px',
  },
}));

function renderMarker(lat: number, lng: number) {
  return (
    <Overlay anchor={[lat, lng]} offset={[20, 20]} key={lat + lng}>
      <FiberManualRecordOutlinedIcon fontSize={'small'} />
    </Overlay>
  );
}

function renderMarkers(assetLocations: Array<turf.Feature>) {
  return assetLocations.map((assetLocation: turf.Feature) => {
    const coordinates = getCoordinates(assetLocation);
    const lat = coordinates[0];
    const lng = coordinates[1];
    return renderMarker(lat, lng);
  });
}

const SingleLocationMap: React.FC = () => {
  const classes = useStyles();
  const {
    clusteredGroups,
    selectedClusteredGroupName,
    selectedClusteredGroupDate,
    selectedAsset,
    clearToAllLocationsScene,
  } = useContext(ApplicationContext);
  const [showDots, setShowDots] = useState(true);

  const centerOfCluster = getCoordinates(
    turfCenter(
      turf.featureCollection(
        clusteredGroups[selectedClusteredGroupDate as string][selectedClusteredGroupName as string],
      ),
    ) as turf.Feature,
  );

  const ShowLocationCheckbox: React.FC = () => {
    const clickCheckbox = () => {
      ReactGA.event({
        category: categories.ViewSettings.analyticKey,
        action: showDots
          ? categories.ViewSettings.actions.hideLocationDots.analyticKey
          : categories.ViewSettings.actions.showLocationDots.analyticKey,
      });
      setShowDots(!showDots);
    };
    return (
      <FormControlLabel
        onChange={clickCheckbox}
        control={<Checkbox checked={showDots} color="primary" />}
        label="Show Location Dots"
        labelPlacement="start"
      />
    );
  };

  function renderMap() {
    return (
      <div className={classes.mapContainer}>
        <Map
          center={centerOfCluster}
          zoom={getZoomLevel(
            clusteredGroups[selectedClusteredGroupDate as string][selectedClusteredGroupName as string],
          )}
          metaWheelZoom={true}
          provider={mapTileProvider}
        >
          <Overlay anchor={centerOfCluster} offset={[20, 20]}>
            <RoomTwoToneIcon fontSize={'large'} />
          </Overlay>

          {showDots &&
            renderMarkers(
              clusteredGroups[selectedClusteredGroupDate as string][selectedClusteredGroupName as string] as Array<
                turf.Feature
              >,
            )}
        </Map>
      </div>
    );
  }

  return (
    <>
      <Grid container alignItems="center">
        <Grid container item xs={7} justify="flex-start">
          <div
            className={classes.topLink}
            onClick={event => {
              event.preventDefault();
              ReactGA.event({
                category: categories.NavigateStay.analyticKey,
                action: categories.NavigateStay.actions.singleAssetBreadcrumb.analyticKey,
              });
              clearToAllLocationsScene();
            }}
          >
            <ArrowBackIosIcon className={classes.icon} />
            Back to overview for {selectedAsset?.title}
          </div>
        </Grid>
        <Grid container item xs={5} justify="flex-end">
          <ShowLocationCheckbox />
        </Grid>
      </Grid>
      {renderMap()}
    </>
  );
};

export default SingleLocationMap;
