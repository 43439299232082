import { FeatureCollection, Point } from '@turf/helpers';
import { JDAssetLocationsGroup } from '../../types';
import { getClusteredPoints } from './get-clustered-points';
import { groupByClusterName } from './group-by-cluster-name';

export function getClustersReducer(assetLocationsGroupedByDay: JDAssetLocationsGroup, clusterRadius: number) {
  return (accum: any, dateOfGroup: string) => {
    const assetLocationsForSingleDay = assetLocationsGroupedByDay[dateOfGroup];

    const clusteredPointsForDay: FeatureCollection<Point> = getClusteredPoints(
      assetLocationsForSingleDay,
      clusterRadius,
    );

    accum[dateOfGroup] = groupByClusterName(clusteredPointsForDay);
    return accum;
  };
}
