import React, { useState } from 'react';
import {
  DateRange,
  EnvironmentVariables,
  JDAsset,
  JDAssetLocation,
  JDOrganization,
  JDUser,
  OauthToken,
  OrganizationAssetStorage,
} from '../types';

const initialApplicationState = {
  commentDialogState: false,
  setCommentDialogState: () => {},
  environmentVariables: null,
  setEnvironmentVariables: () => {},
  oauthToken: null,
  setOauthToken: () => {},
  currentUser: null,
  setCurrentUser: () => {},
  organizations: null,
  setOrganizations: () => {},
  selectedOrganization: null,
  setSelectedOrganization: () => {},
  organizationAssetStorage: {},
  setOrganizationAssetStorage: () => {},
  selectedAsset: null,
  setSelectedAsset: () => {},
  assetLocations: null,
  setAssetLocations: () => {},
  clusteredGroups: null,
  setClusteredGroups: () => {},
  selectedClusteredGroupDate: null,
  setSelectedClusteredGroupDate: () => {},
  selectedClusteredGroupName: null,
  setSelectedClusteredGroupName: () => {},
  bucketMetadata: null,
  setBucketMetadata: () => {},
  infoModalState: false,
  setInfoModalState: () => {},
  centroids: null,
  setCentroids: () => {},
  centerOfCentroids: null,
  setCenterOfCentroids: () => {},
  selectAsset: () => {},
  selectOrganization: () => {},
  selectClusteredGroupDate: () => {},
  clearToAllLocationsScene: () => {},
  clearToAllAssetsScene: () => {},
  clearToSingleLocationScene: () => {},
  applicationError: null,
  setApplicationError: () => {},
  toBeDeletedAssetId: null,
  setToBeDeletedAssetId: () => {},
  dateRange: null,
  setDateRange: () => {},
  showSplashScreen: true,
  setShowSplashScreen: () => {},
  filteredAssets: null,
  setFilteredAssets: () => {},
  assetsFetchInProgress: false,
  setAssetsFetchInProgress: () => {},
  totalOrganizations: 0,
  setTotalOrganizations: () => {},
} as AgPointApplicationContext;

const ApplicationContext = React.createContext(initialApplicationState);

export default ApplicationContext;

export interface AgPointApplicationContext {
  commentDialogState: boolean;
  setCommentDialogState: Function;
  environmentVariables: EnvironmentVariables | null;
  setEnvironmentVariables: (environmentVariables: EnvironmentVariables) => void;
  oauthToken: OauthToken | null;
  setOauthToken: Function;
  currentUser: JDUser | null;
  setCurrentUser: (user: JDUser) => void;
  organizations: Array<JDOrganization> | null;
  setOrganizations: (organizations: Array<JDOrganization>) => void;
  selectedOrganization: JDOrganization | null;
  setSelectedOrganization: (organization: JDOrganization) => void;
  organizationAssetStorage: OrganizationAssetStorage;
  setOrganizationAssetStorage: (organizationAssetStorage: OrganizationAssetStorage) => void;
  selectedAsset: JDAsset | null;
  setSelectedAsset: Function;
  assetLocations: Array<JDAssetLocation> | null;
  setAssetLocations: Function;
  clusteredGroups: any | null;
  setClusteredGroups: Function;
  selectedClusteredGroupDate: string | null;
  setSelectedClusteredGroupDate: Function;
  selectedClusteredGroupName: string | null;
  setSelectedClusteredGroupName: Function;
  bucketMetadata: any;
  setBucketMetadata: Function;
  infoModalState: boolean;
  setInfoModalState: Function;
  centroids: any | null;
  setCentroids: Function;
  centerOfCentroids: any | null;
  setCenterOfCentroids: Function;
  selectAsset: Function;
  selectOrganization: Function;
  selectClusteredGroupDate: Function;
  clearToAllLocationsScene: Function;
  clearToAllAssetsScene: Function;
  clearToSingleLocationScene: Function;
  applicationError: any | null;
  setApplicationError: Function;
  toBeDeletedAssetId: string | null;
  setToBeDeletedAssetId: Function;
  dateRange: DateRange | null;
  setDateRange: (dateRange: DateRange) => void;
  showSplashScreen: boolean;
  setShowSplashScreen: Function;
  filteredAssets: JDAsset[] | null;
  setFilteredAssets: (assets: JDAsset[]) => void;
  assetsFetchInProgress: boolean;
  setAssetsFetchInProgress: (inProgress: boolean) => void;
  totalOrganizations: number;
  setTotalOrganizations: (totalOrganizations: number) => void;
}

export enum Scenes {
  NoAssetsScene,
  AllAssetsScene,
  SplashScreenScene,
  AllLocationsScene,
  SingleLocationScene,
  NoAssetLocationsScene,
  LoadingScene,
}

export const useApplicationContext = (): AgPointApplicationContext => {
  const [applicationError, setApplicationError] = useState<any | null>();
  const [assetLocations, setAssetLocations] = useState<JDAssetLocation[] | null>();
  const [organizationAssetStorage, setOrganizationAssetStorage] = useState<OrganizationAssetStorage>();
  const [bucketMetadata, setBucketMetadata] = useState<any | null>();
  const [centerOfCentroids, setCenterOfCentroids] = useState<any | null>();
  const [centroids, setCentroids] = useState<any | null>();
  const [clusteredGroups, setClusteredGroups] = useState<any | null>();
  const [commentDialogState, setCommentDialogState] = useState(false);
  const [currentUser, setCurrentUser] = useState<JDUser | null>();
  const [environmentVariables, setEnvironmentVariables] = useState<EnvironmentVariables | null>();
  const [infoModalState, setInfoModalState] = useState(false);
  const [oauthToken, setOauthToken] = useState<OauthToken | null>();
  const [organizations, setOrganizations] = useState<JDOrganization[] | null>();
  const [selectedAsset, setSelectedAsset] = useState<JDAsset | null>();
  const [selectedClusteredGroupDate, setSelectedClusteredGroupDate] = useState<string | null>();
  const [selectedClusteredGroupName, setSelectedClusteredGroupName] = useState<string | null>();
  const [selectedOrganization, setSelectedOrganization] = useState<JDOrganization | null>();
  const [dateRange, setDateRange] = useState<DateRange | null>();
  const [showSplashScreen, setShowSplashScreen] = useState<boolean>(true);
  const [filteredAssets, setFilteredAssets] = useState<JDAsset[] | null>();
  const [assetsFetchInProgress, setAssetsFetchInProgress] = useState<boolean>();
  const [totalOrganizations, setTotalOrganizations] = useState<number>();

  const clearToNoAssetsScene = () => {
    setSelectedOrganization(null);
    setSelectedAsset(null);
    setAssetLocations(null);
    setClusteredGroups(null);
    setCentroids(null);
    setCenterOfCentroids(null);
    setBucketMetadata(null);
    setSelectedClusteredGroupDate(null);
    setSelectedClusteredGroupName(null);
  };

  const clearToAllAssetsScene = () => {
    setSelectedAsset(null);
    setAssetLocations(null);
    setClusteredGroups(null);
    setCentroids(null);
    setCenterOfCentroids(null);
    setBucketMetadata(null);
    setSelectedClusteredGroupDate(null);
    setSelectedClusteredGroupName(null);
  };

  const clearToAllLocationsScene = () => {
    setSelectedClusteredGroupName(null);
  };

  const selectAsset = (asset: JDAsset) => {
    clearToAllAssetsScene();
    setSelectedAsset(asset);
  };

  const selectClusteredGroupDate = (date: string) => {
    clearToAllLocationsScene();
    setSelectedClusteredGroupDate(date);
  };

  const selectOrganization = (organization: JDOrganization) => {
    clearToNoAssetsScene();
    setSelectedOrganization(organization);
  };

  const clearToSingleLocationScene = () => {
    return;
  };

  const [toBeDeletedAssetId, setToBeDeletedAssetId] = useState(null);

  return {
    commentDialogState,
    setCommentDialogState,
    environmentVariables,
    setEnvironmentVariables,
    oauthToken,
    setOauthToken,
    currentUser,
    setCurrentUser,
    organizations,
    setOrganizations,
    selectedOrganization,
    setSelectedOrganization,
    organizationAssetStorage,
    setOrganizationAssetStorage,
    selectedAsset,
    setSelectedAsset,
    assetLocations,
    setAssetLocations,
    clusteredGroups,
    setClusteredGroups,
    selectedClusteredGroupDate,
    setSelectedClusteredGroupDate,
    selectedClusteredGroupName,
    setSelectedClusteredGroupName,
    bucketMetadata,
    setBucketMetadata,
    infoModalState,
    setInfoModalState,
    centroids,
    setCentroids,
    centerOfCentroids,
    setCenterOfCentroids,
    selectAsset,
    selectOrganization,
    selectClusteredGroupDate,
    clearToAllLocationsScene: clearToAllLocationsScene,
    clearToAllAssetsScene: clearToAllAssetsScene,
    clearToSingleLocationScene: clearToSingleLocationScene,
    applicationError,
    setApplicationError,
    toBeDeletedAssetId,
    setToBeDeletedAssetId,
    dateRange,
    setDateRange,
    showSplashScreen,
    setShowSplashScreen,
    filteredAssets,
    setFilteredAssets,
    assetsFetchInProgress,
    setAssetsFetchInProgress,
    totalOrganizations,
    setTotalOrganizations,
  } as AgPointApplicationContext;
};
