import React, { useContext } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import ApplicationContext from '../../../contexts/ApplicationContext';
import im1 from '../../../assets/error-images/action-animal-bronco-bucking.jpg';
import im2 from '../../../assets/error-images/animal-countryside-agriculture-farm.jpg';
import im3 from '../../../assets/error-images/photo-of-white-cow-with-brown-eyes.jpg';
import im4 from '../../../assets/error-images/vintage-car-wrecked-grayscale-photo.jpg';
import im5 from '../../../assets/error-images/wrecked-iphone.jpg';

const useStyles = makeStyles(() => ({
  image: {
    height: '60%',
    display: 'block',
    'margin-left': 'auto',
    'margin-right': 'auto',
    'margin-top': '20px',
    'margin-bottom': '20px',
  },
  text: { textAlign: 'center' },
  button: { display: 'block', 'margin-left': 'auto', 'margin-right': 'auto' },
}));

const images = [im1, im2, im3, im4, im5];

const ApplicationError: React.FC = () => {
  const classes = useStyles();

  const { clearToAllAssetsScene, setApplicationError } = useContext(ApplicationContext);

  const selectImage = () => {
    const ImageCount = 5;
    return images[Math.floor(Math.random() * ImageCount)];
  };

  const clearError = () => {
    setApplicationError(null);
    clearToAllAssetsScene();
  };

  return (
    <>
      <Typography className={classes.text} variant="h2">
        We're sorry, something went wrong.
      </Typography>
      <img className={classes.image} src={selectImage()} alt="Error" />
      <Button className={classes.button} variant="contained" onClick={clearError}>
        Return Home
      </Button>
    </>
  );
};

export default ApplicationError;
