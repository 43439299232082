import { JDAssetLocation, JDAssetLocationsGroup } from '../../types';

function getMonthNumberIn1IndexedArrayFormat(locationTimeStamp: Date) {
  return locationTimeStamp.getMonth() + 1;
}

function getDateString(assetLocation: JDAssetLocation) {
  const locationTimeStamp = new Date(assetLocation.timestamp);

  return (
    locationTimeStamp.getFullYear() +
    `-${getMonthNumberIn1IndexedArrayFormat(locationTimeStamp)}` +
    `-${locationTimeStamp.getDate()}`
  );
}

export const groupByDay = (assetLocations: JDAssetLocation[]): JDAssetLocationsGroup => {
  return assetLocations.reduce((accum: any, assetLocation: JDAssetLocation) => {
    const dateString = getDateString(assetLocation);
    const group = accum[dateString] || [];

    group.push(assetLocation);
    accum[dateString] = group;

    return accum;
  }, {});
};
