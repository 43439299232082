import React, { useContext } from 'react';
import ApplicationContext from '../../contexts/ApplicationContext';
import { useAssetLocations } from '../../hooks/AssetLocations';
import { useClusters } from '../../hooks/Clusters';
import { useMetadata } from '../../hooks/MetaData';
import { useAssets } from '../../hooks/Assets';
import { useClusterCentroids } from '../../hooks/ClusterCentroids';
import { useCenterOfCentroidsForDate } from '../../hooks/CenterOfClusterCentroids';
import LoggedOutContent from './content/LoggedOutContent';
import LoggedInContent from './content/LoggedInContent';
import LoggingInContent from './content/LoggingInContent';
import ApplicationError from './content/ApplicationError';

const Body: React.FC = () => {
  const { oauthToken, applicationError } = useContext(ApplicationContext);
  const clusterRadius = 0.5;
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  useClusters(clusterRadius);
  useMetadata();
  useAssetLocations();
  useAssets();
  useClusterCentroids();
  useCenterOfCentroidsForDate();

  if (applicationError) {
    return <ApplicationError />;
  }

  if (code) {
    return <LoggingInContent />;
  }

  if (oauthToken) {
    return <LoggedInContent />;
  }

  return <LoggedOutContent />;
};

export default Body;
