import {Grid, makeStyles, Paper, Typography} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  card: {
    padding: '5px',
    marginBottom: '10px',
  },
  text: {
    marginTop: '5px',
  },
  title: {
    margin: '8px',
    color: '#0D6F92',
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '40px',
  },
  sidePadding: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  welcomeText: {
    fontSize: '18px',
    lineHeight: '32px',
  },
  mapWrapper: {
    margin: '0px',
    padding: '0px',
    height: '100%',
    width: '100%',
  },
  mapImage: {
    width: '100%',
    height: '100%',
  },
  content: {
    height: 'calc(100vh - 237px)',
    padding: '16px',
    overflow: 'scroll',
    margin: '32px auto',
    marginTop: '0',
    maxWidth: 'calc(100vw - 6rem)',
    paddingTop: 0,
  },
});

const LoggingInContent: React.FC = () => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={`${classes.content}`}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography variant={'h4'} className={classes.title}>
            Logging in, please wait...
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LoggingInContent;
