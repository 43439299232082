import { TurfPoint } from "../../types";

const LAT = 0;
const LNG = 1;

function byCoordinate(coordinateType: number) {
  return (lhs: any, rhs: any) => {
    return lhs.coordinates[coordinateType] - rhs.coordinates[coordinateType];
  };
}

function toCoordinatePiece(coordinateType: number) {
  return (point: any) => {
    return point.coordinates[coordinateType];
  };
}

export function getDiff(points: Array<any>, coordinateType: any) {
  const sortedPieces = points
    .sort(byCoordinate(coordinateType))
    .map(toCoordinatePiece(coordinateType));
  return sortedPieces[sortedPieces.length - 1] - sortedPieces[0];
}

export function getHighestSupportedZoomLevelDiff(
  highestSupportedZoomLevel: number
) {
  return 360 / Math.pow(2, highestSupportedZoomLevel - 1);
}

export function calcZoomLevel(maxDiff: number) {
  return -1 * (Math.log(maxDiff) / Math.log(2) - Math.log(360) / Math.log(2));
}

export function getMaxDiff(features: TurfPoint[]) {
  const points = features.map(feature => {
    return feature.geometry;
  });

  const latDiff = getDiff(points, LAT);
  const lngDiff = getDiff(points, LNG);

  return Math.max(lngDiff, latDiff)
}
