import { getOrganizationAssetsWithLocation } from '../backend/backendService';
import { generateClusters } from '../clusters/main';
import { JDOrganization, OauthToken, EnvironmentVariables } from '../../types';
import { getCentroids } from '../centroids/getCentroids';
import { DateTime } from 'luxon';

const getAssetsWithLocation = async (
  selectedOrganization: JDOrganization | null,
  oauthToken: OauthToken | null,
  environmentVariables: EnvironmentVariables | null,
) => {
  if (selectedOrganization && oauthToken && environmentVariables) {
    const assets: Array<any> = await getOrganizationAssetsWithLocation(
      environmentVariables.apiBaseUrl,
      oauthToken,
      selectedOrganization.id.toString(),
    );
    if (assets === null || assets.length === 0) {
      return null;
    }
    const clusterRadius = 0.5;

    return assets
      .map((asset: any) => {
        asset.clusters = generateClusters(asset.locations, clusterRadius);
        const clusters: any = Object.entries(asset.clusters);
        const centroids: any = getCentroids(asset.clusters);

        return clusters
          .filter((cluster: any) => {
            return !Object.keys(cluster[1])[0].includes('noise');
          })
          .map((cluster: any) => {
            const groupDate: string = cluster[0];
            const groupName: string = Object.keys(cluster[1])[0];
            const features: any = cluster[1][groupName];
            const timeStamps: any = features.map((feature: any) => {
              return feature.properties.time;
            });
            const startTime: any = timeStamps[timeStamps.length - 1];
            const endTime: any = timeStamps[0];
            const start = DateTime.fromISO(startTime);
            const end = DateTime.fromISO(endTime);
            const timeDiff = end.diff(start, ['hours', 'minutes', 'seconds']).toObject();
            const durationString: string = timeDiff.hours + ':' + timeDiff.minutes + ':' + timeDiff.seconds;

            const locationLatitude = parseFloat(centroids[groupDate][groupName].geometry.coordinates[1].toFixed(5));
            const locationLongitude = parseFloat(centroids[groupDate][groupName].geometry.coordinates[0].toFixed(5));

            return {
              assetTitle: asset.title,
              locationLatitude,
              locationLongitude,
              locationStartTime: startTime,
              locationEndTime: endTime,
              durationAtLocation: durationString,
              locationName: null,
              address: null,
            };
          });
      })
      .flat();
  }
  return null;
};

export default getAssetsWithLocation;
