import { Grid, makeStyles, Typography } from '@material-ui/core';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import React from 'react';

const useStyles = makeStyles({
  legend: {
    width: '100%',
    padding: '24px',
  },
});

const AssetsLastKnowLocationsLegend: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.legend} container justify={'center'} alignItems={'center'}>
      <Grid container item xs={12} justify={'center'} alignItems={'center'} direction={'row'}>
        <PhoneAndroidIcon fontSize="small" />
        <Typography>Asset</Typography>
      </Grid>
    </Grid>
  );
};

export default AssetsLastKnowLocationsLegend;
