import React, { useContext } from 'react';
import ApplicationContext from '../../../../contexts/ApplicationContext';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const DateRangeDisclaimer: React.FC = () => {
  const { dateRange } = useContext(ApplicationContext);

  const pStyle: CSSProperties = {
    textAlign: 'center',
  };

  if (!dateRange) {
    return null;
  }

  const { startDate, endDate } = dateRange;
  const startDateText = startDate?.format('dddd, MMMM D, yyyy');
  const endDateText = endDate?.format('dddd, MMMM D, yyyy');

  switch (true) {
    case !!(!startDate && !endDate):
      return null;
    case !!(startDate && endDate):
      return (
        <p style={pStyle}>
          Showing locations in date range {startDateText}-{endDateText}
        </p>
      );
    case !!(!startDate && endDate):
      return <p style={pStyle}>Showing locations before {endDateText}</p>;
    case !!(startDate && !endDate):
      return <p style={pStyle}>Showing locations between {startDateText} and today</p>;
    default:
      return null
  }
};

export default DateRangeDisclaimer;
