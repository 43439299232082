import { ThemeProvider } from '@material-ui/core';
import React from 'react';
import AgPointAppBar from './components/header/AgPointAppBar';
import AgPointFooter from './components/footer/Footer';
import ApplicationContext, { useApplicationContext } from './contexts/ApplicationContext';
import Body from './components/body/Body';
import theme from './themes/DefaultTheme';
import InfoModal from './components/dialogs/InfoModal';
import DeleteAssetDialog from './components/dialogs/DeleteAssetDialog';
import CommentsDialog from './components/dialogs/CommentsDialog';

const Router: React.FC = () => {
  return (
    <ApplicationContext.Provider value={useApplicationContext()}>
      <ThemeProvider theme={theme}>
        <CommentsDialog />
        <InfoModal />
        <DeleteAssetDialog />
        <AgPointAppBar />
        <Body />
        <AgPointFooter />
      </ThemeProvider>
    </ApplicationContext.Provider>
  );
};

export default Router;
