export const categories = {
  NavigateAway: {
    analyticKey: 'Navigate-Away',
    actions: {
      appleAppStore: {
        analyticKey: 'apple-app-store',
      },
      googlePlayStore: {
        analyticKey: 'google-play-store',
      },
      sourceAlliesWebsite: {
        analyticKey: 'source-allies-website',
      },
    },
  },
  NavigateStay: {
    analyticKey: 'Navigate-Stay',
    actions: {
      appLogo: {
        analyticKey: 'app-logo',
      },
      allAssetsListItem: {
        analyticKey: 'all-assets-list-item',
      },
      allAssetsBreadcrumb: {
        analyticKey: 'all-assets-breadcrumb',
      },
      singleAssetListItem: {
        analyticKey: 'single-asset-list-item',
      },
      singleAssetBreadcrumb: {
        analyticKey: 'single-asset-breadcrumb',
      },
      singleAssetMapButton: {
        analyticKey: 'single-asset-map-button',
      },
      organizationListItem: {
        analyticKey: 'organization-list-item',
      },
      dateChooser: {
        analyticKey: 'date-chooser',
      },
      clusterMapButton: {
        analyticKey: 'cluster-map-button',
      },
      noiseMapButton: {
        analyticKey: 'noise-map-button',
      },
    },
  },
  Modals: {
    analyticKey: 'modals',
    actions: {
      infoOpen: {
        analyticKey: 'info-open',
      },
      infoClose: {
        analyticKey: 'info-close',
      },
    },
  },
  Authentication: {
    analyticKey: 'authentication',
    actions: {
      login: {
        analyticKey: 'login',
      },
      logout: {
        analyticKey: 'logout',
      },
    },
  },
  ViewSettings: {
    analyticKey: 'view-settings',
    actions: {
      hideLocationDots: {
        analyticKey: 'hide-location-dots',
      },
      showLocationDots: {
        analyticKey: 'show-location-dots',
      },
    },
  },
};
