import { getAssetsForOrganization } from '../services/organization/organizationStorageService';
import { JDAsset, JDOrganization, JDAssetLocation, OrganizationAssetStorage } from '../types';
import { Scenes } from './ApplicationContext';

export const clearSelectedAsset = (
  selectAsset: Function,
  setSelectedClusteredGroupDate: Function,
  setSelectedClusteredGroupName: Function,
  setClusteredGroups: Function,
  setBucketMetadata: Function,
  setCentroids: Function,
  setCenterOfCentroids: Function,
  setShowSplashScreen: Function,
) => {
  selectAsset(null);
  setSelectedClusteredGroupDate(null);
  setSelectedClusteredGroupName(null);
  setClusteredGroups(null);
  setBucketMetadata(null);
  setCentroids(null);
  setCenterOfCentroids(null);
  setShowSplashScreen(false);
};

export const clearSelectedAssetLocations = (
  setSelectedClusteredGroupDate: Function,
  setSelectedClusteredGroupName: Function,
  setClusteredGroups: Function,
  setBucketMetadata: Function,
  setCentroids: Function,
  setCenterOfCentroids: Function,
) => {
  setSelectedClusteredGroupDate(null);
  setSelectedClusteredGroupName(null);
  setClusteredGroups(null);
  setBucketMetadata(null);
  setCentroids(null);
  setCenterOfCentroids(null);
};

export const getApplicationScene = (
  organizations: Array<JDOrganization> | null,
  organizationAssetStorage: OrganizationAssetStorage | null,
  selectedOrganization: JDOrganization | null,
  selectedAsset: JDAsset | null,
  clusteredGroups: any | null,
  centroids: any | null,
  centerOfCentroids: any | null,
  selectedClusteredGroupName: string | null,
  selectedClusteredGroupDate: string | null,
  showSplashScreen: boolean | null,
  assetLocations: Array<JDAssetLocation> | null,
): Scenes | null => {
  switch (true) {
    case showSplashScreen:
      return Scenes.SplashScreenScene;
    case organizations &&
      getAssetsForOrganization(selectedOrganization, organizationAssetStorage).assets.length === 0:
      return Scenes.NoAssetsScene;
    case !selectedAsset:
      return Scenes.AllAssetsScene;
    case selectedAsset &&
      clusteredGroups &&
      centroids &&
      centerOfCentroids &&
      !selectedClusteredGroupName &&
      !!selectedClusteredGroupDate:
      return Scenes.AllLocationsScene;
    case clusteredGroups && selectedAsset && centroids && !!selectedClusteredGroupName:
      return Scenes.SingleLocationScene;
    case selectedAsset && assetLocations && assetLocations.length === 0:
      return Scenes.NoAssetLocationsScene;
    default:
      return Scenes.LoadingScene;
  }
};
