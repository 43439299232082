import { ClusterDateGroup, Address } from '../../types';

export const getAddress = async (
  centroids: ClusterDateGroup,
  selectedClusteredGroupDate: string | null,
  selectedClusteredGroupName: string | null,
) => {
  if (selectedClusteredGroupDate && selectedClusteredGroupName) {
    const centroid = centroids[selectedClusteredGroupDate][selectedClusteredGroupName];

    if (centroid) {
      const coordinates = centroid.geometry.coordinates;
      const lat = coordinates[1];
      const lng = coordinates[0];
      const endpoint = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`;
      const response = await fetch(endpoint, { method: 'GET' });

      if (response) {
        if (response.status === 500) {
          return 'Address Unavailable';
        } else if (response.status === 404) {
          return 'Address Not Found';
        }
        const body = await response.json();
        const address: Address = {
          locationName: body.name,
          city: body.address.city,
          // eslint-disable-next-line @typescript-eslint/camelcase
          house_number: body.address.house_number,
          postcode: body.address.postcode,
          road: body.address.road,
          state: body.address.state,
        };
        return address;
      }
    }
  }
};
