import { AssetsServiceResponse, JDAsset, GetOrganizationAssetsResponse, OauthToken, JDAssetRaw } from '../../types';
import { toAsset } from '../backend/jd-response-mappers/Asset';

export const fetchAssetsForOrganization = async (
  platformUrl: string,
  awsProxyUrl: string,
  oAuthToken: OauthToken,
): Promise<AssetsServiceResponse> => {
  const encodedAccessToken = encodeURIComponent(oAuthToken.accessToken);

  let responseBody: GetOrganizationAssetsResponse;
  try {
    const response: Response = await fetch(`${awsProxyUrl}/api?endpoint=${encodeURIComponent(platformUrl)}`, {
        mode: 'cors',
        method: 'GET',
        headers: {
            "Accept": "application/vnd.deere.axiom.v3+json",
            "Authorization": `Bearer ${encodedAccessToken}`
        }
    });
    responseBody = await response.json();
  } catch (e) {
    throw new Error(e.reason);
  }

  const { links, values, total } = responseBody;
  let nextLink = null;
  if (links) {
    nextLink = links.filter((link: any) => link.rel === 'nextPage')[0]?.uri;
  }
  const filteredAssets = values
    .map((asset: JDAssetRaw) => toAsset(asset))
    .filter(
      (asset: JDAsset) =>
        asset.assetCategory === 'DEVICE' && asset.assetType === 'SENSOR' && asset.assetSubType === 'OTHER',
    );
  return {
    assets: filteredAssets,
    nextPageLink: nextLink,
    total: total,
  };
};
