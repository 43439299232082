import { AppBar, ButtonBase, Grid, Hidden, Button, makeStyles, Toolbar, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import ApplicationContext from '../../contexts/ApplicationContext';
import { useAuthUser, useAccessToken } from '../../hooks/auth/Auth';
import { getAccessToken } from '../../hooks/auth/authorizeFromCode';
import agPointLogo from '../../assets/agpoint.svg';
import LoginButton from './LoginButton';
import { useEnvironmentVariables } from '../../hooks/UseEnvironmentVariables';
import { useOrganizations } from '../../hooks/Organizations';
import ReactGA from 'react-ga';
import { categories } from '../../google-analytics/constants';
import { useGoogleAnalytics } from '../../hooks/GoogleAnalytics';
import AddFeedbackIcon from '@material-ui/icons/Feedback';


const useStyles = makeStyles(theme => ({
  titleContainer: {
    'text-align': 'center',
  },
  authContainer: {
    height: '100%',
    position: 'relative',
  },
  userName: theme.verticalAlignCenterText,
  title: {
    display: 'flex',
    'flex-direction': 'row',
    'align-items': 'center',
    height: '100%',
    'justify-content': 'center',
    color: 'white',
    'vertical-align': 'center',
    'font-weight': 'bold',
  },
  logo: {
    height: 36,
    width: 36,
    'padding-left': '.5rem',
  },
  titleWrapper: {
    height: '100%',
  },
  feedbackIcon: {
    'padding-right': '.5rem',
  },
}));

const AgPointAppBar: React.FC = () => {
  const classes = useStyles();
  const { oauthToken, currentUser, environmentVariables, clearToAllAssetsScene, setCommentDialogState } = useContext(
    ApplicationContext,
  );
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");

  useAuthUser();
  useEnvironmentVariables();
  useGoogleAnalytics();
  React.useEffect( () => {
    if (code && environmentVariables?.uiFrontendUrl && environmentVariables?.awsProxyUrl) {
      getAccessToken(
        environmentVariables.uiFrontendUrl,
        environmentVariables.awsProxyUrl);
    }
  }, [code, environmentVariables]);

  useAccessToken();
  useOrganizations();

  function renderAuthSection() {
    return (
      <Grid container justify="flex-end" className={classes.authContainer}>
        <Hidden xsDown>
          <Grid item xs={9}>
            {currentUser && (
              <Typography className={classes.userName}>
                Hello {currentUser.givenName} {currentUser.familyName}
              </Typography>
            )}
          </Grid>
        </Hidden>
        <Grid item sm={3}>
          {environmentVariables && <LoginButton isLoggedIn={!!oauthToken} />}
        </Grid>
      </Grid>
    );
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <Grid container>
          <Grid item xs={3}>
            {currentUser && (
              <Button
                startIcon={<AddFeedbackIcon className={classes.feedbackIcon} />}
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  setCommentDialogState(true);
                }}
                data-testid="Give-Feedback-Button"
              >
                <Hidden xsDown>
                  <Typography variant="button">Give Feedback</Typography>
                </Hidden>
              </Button>
            )}
          </Grid>
          <Grid item className={classes.titleContainer} xs={6}>
            <ButtonBase
              className={classes.titleWrapper}
              onClick={() => {
                ReactGA.event({
                  category: categories.NavigateStay.analyticKey,
                  action: categories.NavigateStay.actions.appLogo.analyticKey,
                });
                clearToAllAssetsScene();
              }}
            >
              <Typography variant="h6" className={classes.title}>
                AgPoint
                <img src={agPointLogo} className={classes.logo} alt={'AgPoint Logo'} />
              </Typography>
            </ButtonBase>
          </Grid>
          <Grid item xs={3}>
            {renderAuthSection()}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default AgPointAppBar;
