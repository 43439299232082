import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import ApplicationContext, { Scenes } from '../../../../contexts/ApplicationContext';
import AllLocationsMap from './maps/AllLocationsMap';
import AssetsLastKnownLocationsMap from './maps/AssetsLastKnownLocationsMap';
import SingleLocationMap from './maps/SingleLocationMap';
import EmptyMap from './maps/EmptyMap';
import SplashScreen from './SplashScreen';
import { getApplicationScene } from '../../../../contexts/ContextUtils';

const useStyles = makeStyles({
  container: {
    width: '100%',
  },
  progress: {
    width: '90px',
    height: '90px',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    position: 'absolute',
    margin: 'auto',
  },
});

const CenterContent: React.FC = () => {
  const classes = useStyles();
  const {
    organizations,
    organizationAssetStorage,
    selectedOrganization,
    selectedAsset,
    clusteredGroups,
    centroids,
    centerOfCentroids,
    selectedClusteredGroupDate,
    selectedClusteredGroupName,
    assetLocations,
    showSplashScreen,
  } = useContext(ApplicationContext);

  let content;

  const applicationScene: Scenes | null = getApplicationScene(
    organizations,
    organizationAssetStorage,
    selectedOrganization,
    selectedAsset,
    clusteredGroups,
    centroids,
    centerOfCentroids,
    selectedClusteredGroupName,
    selectedClusteredGroupDate,
    showSplashScreen,
    assetLocations,
  );

  switch (applicationScene) {
    case Scenes.AllAssetsScene:
      content = <AssetsLastKnownLocationsMap />;
      break;
    case Scenes.SplashScreenScene:
      content = <SplashScreen />;
      break;
    case Scenes.AllLocationsScene:
      content = <AllLocationsMap />;
      break;
    case Scenes.SingleLocationScene:
      content = <SingleLocationMap />;
      break;
    case Scenes.NoAssetLocationsScene:
      content = <EmptyMap />;
      break;
    case Scenes.LoadingScene:
    default:
      content = <CircularProgress size="100" className={classes.progress} />;
      break;
  }

  return (
    <Grid
      item
      container
      direction="column"
      wrap="nowrap"
      justify="space-between"
      alignItems="center"
      className={classes.container}
    >
      {content}
    </Grid>
  );
};

export default CenterContent;
