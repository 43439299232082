import { JDAssetLocation, JDAssetLocationsGroup } from '../../types';
import { groupByDay } from './grouper';
import { getClustersReducer } from './reducer';

export const generateClusters = (assetLocations: JDAssetLocation[], clusterRadius: number) => {
  const assetLocationsGroupedByDay: JDAssetLocationsGroup = groupByDay(assetLocations);

  return Object.keys(assetLocationsGroupedByDay).reduce(
    getClustersReducer(assetLocationsGroupedByDay, clusterRadius),
    {},
  );
};
