import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import AppStoreBadge from './badges/AppStoreBadge';
import PlayStoreBadge from './badges/PlayStoreBadge';
import SourceAlliesLogoDark from './badges/SourceAlliesLogoDark';
import ReactGA from 'react-ga';
import { categories } from '../../google-analytics/constants';

const useStyles = makeStyles({
  sourceLogoContainerText: {
    display: 'block',
    'font-size': '14px',
  },
  sourceBadge: {
    'align-self': 'flex-start',
    'text-align': 'right',
  },
  sourceBadgeContent: {
    display: 'inline-block',
    'text-align': 'left',
  },
  badgeContainer: {
    margin: 'auto',
    padding: '0 12px',
    backgroundColor: '#E2E0E0',
    width: '100%',
  },
});

const AgPointFooter: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={6} justify={'space-between'} alignItems={'flex-end'} className={classes.badgeContainer}>
      <Grid item>
        <AppStoreBadge />
        <PlayStoreBadge />
      </Grid>
      <Grid item className={classes.sourceBadge}>
        <div className={classes.sourceBadgeContent}>
          <Typography variant="h5" className={classes.sourceLogoContainerText}>
            Version {process.env.REACT_APP_VERSION} developed by
          </Typography>
          <Button
            variant="text"
            disableFocusRipple={true}
            disableRipple={true}
            onClick={() => {
              ReactGA.event({
                category: categories.NavigateAway.analyticKey,
                action: categories.NavigateAway.actions.sourceAlliesWebsite.analyticKey,
              });
              window.open('https://sourceallies.com', '_blank');
            }}
            style={{ backgroundColor: 'transparent', padding: 0 }}
          >
            <SourceAlliesLogoDark />
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default AgPointFooter;
