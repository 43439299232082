import React, { useContext, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ApplicationContext from '../../contexts/ApplicationContext';
import { makeStyles, TextareaAutosize } from '@material-ui/core';

const titleStyles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(4),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof titleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(titleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const styles = makeStyles(theme => ({
  textArea: {
    width: '97%',
  },
  content: {
    padding: theme.spacing(2),
  },
  actions: {
    margin: 0,
    padding: theme.spacing(1),
  },
}));
export default function CommentsDialog() {
  const classes = styles();
  const [messageContent, setMessageContent] = useState('');

  const { setCommentDialogState, commentDialogState } = useContext(ApplicationContext);

  const sendMessage = async () => {
    setCommentDialogState(false);
    if (messageContent) {
      const username = 'azure@sourceallies.com';
      const subject = 'Comment Or Concern';
      const message = messageContent;
      const mailToLink = `mailto:${username}?subject=${subject}&body=${message}`;
      window.location.href = mailToLink;
    }
  };

  const handleClose = () => {
    setCommentDialogState(false);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={commentDialogState}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {'Leave us a comment'}
      </DialogTitle>
      <MuiDialogContent className={classes.content} dividers>
        <TextareaAutosize
          onBlur={event => {
            setMessageContent(event.target.value);
          }}
          className={classes.textArea}
          aria-label="minimum height"
          rowsMin={5}
          placeholder="Please let us know how we are doing, or use this form to report a bug."
        />
      </MuiDialogContent>
      <MuiDialogActions className={classes.actions}>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={sendMessage} autoFocus variant="contained" color="primary">
          Send
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
}
