import { calcZoomLevel, getHighestSupportedZoomLevelDiff, getMaxDiff } from './zoom-level-helpers';
import { TurfPoint } from '../../types';

const HIGHEST_SUPPORTED_ZOOM_LEVEL = 19;

export function getZoomLevel(
  features: TurfPoint[],
  highestSupportedZoomLevel: number = HIGHEST_SUPPORTED_ZOOM_LEVEL,
): number {
  if (features.length === 1) {
    return 16;
  }

  const maxDiff = getMaxDiff(features);
  if (maxDiff < getHighestSupportedZoomLevelDiff(highestSupportedZoomLevel)) {
    return highestSupportedZoomLevel;
  }
  return Math.max(calcZoomLevel(maxDiff), 1);
}
