import { ONE_MINUTE_IN_MILLIS } from '../time/timeConstants';
import { convertMillisToMinutes } from '../time/timeUtils';
import GeoJSON from 'geojson';

export const toTimeSpent = (acc: any[], coLinearTimeGroup: Array<GeoJSON.Feature>, index: number) => {
  const timeSpentInMillis =
    coLinearTimeGroup.length > 1
      ? Math.abs(
          new Date(coLinearTimeGroup[0]?.properties?.time).getTime() -
            new Date(coLinearTimeGroup[coLinearTimeGroup?.length - 1].properties?.time).getTime(),
        )
      : ONE_MINUTE_IN_MILLIS;
  acc[index] = {
    features: coLinearTimeGroup,
    timeSpent: convertMillisToMinutes(timeSpentInMillis),
  };

  return acc;
};
