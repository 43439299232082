import {OauthToken} from '../../types';
import {makeUrl} from "../oauth/getURLs";


export const backendGet = async (
  path: string,
  oAuthToken: OauthToken,
  apiBaseUrl: string,
  queryParams?: any,
): Promise<any> => {
  queryParams = {
    ...queryParams,
    accessToken: oAuthToken.accessToken,
  };

  const fetchOptions = {
    method: 'GET',
    headers: {
      "Accept": "application/json",
    }
  }

  const url = makeUrl(apiBaseUrl + path, queryParams);
  const res = await fetch(url, fetchOptions);
  if(!res.ok) {
    throw new Error(`HTTP GET ${path} returned status ${res.status}`);
  }

  return await res.json();
};

export const backendDelete = (path: string, oAuthToken: OauthToken, apiBaseUrl: string): Promise<any> => {
  return fetch(
    apiBaseUrl +
      path +
      '?accessToken=' +
      encodeURIComponent(oAuthToken.accessToken),
    { method: 'DELETE' },
  );
};
