import { JDAssetLocationRaw } from '../../../types';

export const toAssetLocation = (value: JDAssetLocationRaw) => {
  let parsedGeometry;
  try {
    parsedGeometry = JSON.parse(value.geometry);
  } catch (e) {
    //
  }
  return {
    ...value,
    geometry: parsedGeometry,
  };
};
