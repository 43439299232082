import { AssetStorage, JDOrganization, OrganizationAssetStorage } from '../../types';

export const getAssetsForOrganization = (
  selectedOrganization: JDOrganization | null,
  organizationAssetStorage: OrganizationAssetStorage | null,
): AssetStorage => {
  const organizationId = selectedOrganization?.id;

  if (organizationId && organizationAssetStorage && organizationAssetStorage[organizationId]) {
    return organizationAssetStorage[organizationId];
  }
  return { total: 0, assets: [] };
};
