import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Styles } from '@material-ui/core/styles/withStyles';
import matchSorter from 'match-sorter';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

export const styles: Styles<Theme, {}> = {
  textField: {
    width: '100%',
    marginTop: '5px',
    marginBottom: '3px',
  },
};

const useStyles = makeStyles(styles);

interface Props {
  searchList: any[];
  searchKeyProperty?: string;
  filteredContextSetter: any;
  className?: string;
}

const SearchBar: React.FC<Props> = props => {
  const [searchText, setSearchText] = useState<string>('');
  const { searchList, searchKeyProperty, filteredContextSetter } = props;
  const { textField } = useStyles();

  const items = searchList?.length ? searchList : [];

  useEffect(() => {
    if (!searchText) {
      filteredContextSetter(items);
      return;
    }

    const matchedItems = getMatchResults(items, searchText, searchKeyProperty);

    filteredContextSetter(matchedItems);
  }, [searchText]);

  const getMatchResults = (items: any[], searchText: string, searchKey: string | undefined) => {
    if (searchKey) {
      return matchSorter(items, searchText, { keys: [searchKey] });
    }
    return matchSorter(items, searchText);
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <TextField
      aria-label="Search Bar"
      inputProps={{ label: 'Search Bar Input' }}
      value={searchText}
      onChange={event => setSearchText(event.target.value)}
      className={textField}
      label={'Filter Assets'}
      type="search"
    />
  );
};

SearchBar.propTypes = {
  searchList: PropTypes.array.isRequired,
  searchKeyProperty: PropTypes.string,
  filteredContextSetter: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default SearchBar;
