import { useContext, useEffect } from 'react';
import ApplicationContext from '../../contexts/ApplicationContext';
import { getAndSetCurrentUser } from './GetAndSetCurrentUser';

export const useAuthUser = () => {
  const { oauthToken, setCurrentUser, environmentVariables } = useContext(ApplicationContext);

  useEffect(() => {
    getAndSetCurrentUser(environmentVariables, oauthToken, setCurrentUser);
  }, [oauthToken, setCurrentUser, environmentVariables]);
};
