import { useContext, useEffect } from "react";
import ApplicationContext from "../contexts/ApplicationContext";
import { getBucketMetadata } from "../services/metadata/main";

export const getAndSetMetaData = (
  clusteredGroups: any,
  setBucketMetadata: Function
) => {
  if (clusteredGroups) {
    const metadata = getBucketMetadata(clusteredGroups);
    setBucketMetadata(metadata);
  }
};

export const useMetadata = () => {
  const { clusteredGroups, setBucketMetadata } = useContext(ApplicationContext);

  useEffect(() => {
    getAndSetMetaData(clusteredGroups, setBucketMetadata);
  }, [clusteredGroups, setBucketMetadata]);
};
