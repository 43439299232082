import { useContext, useEffect } from 'react';
import ApplicationContext from '../contexts/ApplicationContext';
import { getAssetLocations } from '../services/backend/backendService';
import { EnvironmentVariables, JDAsset, OauthToken, DateRange } from '../types';

export const getAndSetAssetLocations = async (
  oauthToken: OauthToken | null,
  selectedAsset: JDAsset | null,
  environmentVariables: EnvironmentVariables | null,
  setAssetLocations: Function,
  dateRange: DateRange | null,
) => {
  if (!(oauthToken && selectedAsset && environmentVariables)) return;

  let params: any = {};

  if (!!dateRange?.startDate) {
    params.startDate = dateRange.startDate.toISOString();
  }

  if (!!dateRange?.endDate) {
    params.endDate = dateRange.endDate.toISOString();
  }

  const values = await getAssetLocations(
    environmentVariables.apiBaseUrl,
    oauthToken,
    selectedAsset.id.toString(),
    dateRange ? params : undefined,
  );

  setAssetLocations(values);
};

export const useAssetLocations = () => {
  const { oauthToken, selectedAsset, setAssetLocations, environmentVariables, dateRange } = useContext(
    ApplicationContext,
  );

  useEffect(() => {
    getAndSetAssetLocations(oauthToken, selectedAsset, environmentVariables, setAssetLocations, dateRange);
  }, [selectedAsset, oauthToken, setAssetLocations, environmentVariables, dateRange]);
};
