import querystring from "query-string";

export interface ProxyUrls {
  login: string;
  authorizeToken: string;
  refreshToken: string;
}

export function getScopes():string {
  return 'offline_access eq1 eq2 ag1 ag2 org1';
}


export const createQueryParameterString = (queryParams: any):string => {
  if (!queryParams) {
    return '';
  }

  const options = {
    encode: true,
    strict: false
  } as querystring.StringifyOptions;

  return querystring.stringify(queryParams, options);
};

export const makeUrl = (baseUrl:string, queryParams:any ): string => {
  const queryParamString = createQueryParameterString(queryParams);
  if(queryParamString) {
    return `${baseUrl}?${queryParamString}`
  } else {
    return baseUrl
  }
}

export const getURLs = (awsProxyUrl:string): ProxyUrls => {
    return {
      login: `${awsProxyUrl}/login`,
      authorizeToken: `${awsProxyUrl}/access`,
      refreshToken: `${awsProxyUrl}/refresh`,
    } as ProxyUrls;
};

export const getLoginUrl = (uiFrontendUrl: string, awsProxyUrl:string): string => {
  const queryParams = {
    scope: getScopes(),
    redirect_uri: uiFrontendUrl,
  };
  return makeUrl(getURLs(awsProxyUrl).login, queryParams);
};

export const getAuthorizeUrl = (uiFrontendUrl: string, awsProxyUrl:string, code: string, state: string): string => {
  const queryParams = {
    code,
    state,
    redirect_uri: uiFrontendUrl,
  };
  return makeUrl(getURLs(awsProxyUrl).authorizeToken, queryParams);
};

export const getRefreshTokenUrl = (uiFrontendUrl: string, awsProxyUrl:string, refreshToken: string): string => {
  const queryParams = {
    scope: getScopes(),
    redirect_uri: uiFrontendUrl,
    refresh_token: refreshToken
  };
  return makeUrl(getURLs(awsProxyUrl).refreshToken, queryParams);
};